// Customizable Area Start
import React from "react";
import {
    Box,
    styled,
    Grid,
    Button
} from "@mui/material";
import EmailRegistrationCategoriesController, {
    Props,
} from "./EmailRegistrationCategoriesController";
import { backCategories, logoSvg } from "./assets";
import { ResolveCondition } from "../../../blocks/utilities/src/CustomHelpers";

export default class EmailRegistrationCategories extends EmailRegistrationCategoriesController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <>
            <StyledWrapperCategories>
                <Grid className="mainDiv">
                    <Grid className="imageContainer">
                        <Box className="backCategories">
                            <img src={backCategories} alt="backCategories" className="backCategoriesImage" />
                        </Box>
                        <Box className="logoSvg">
                            <img src={logoSvg} alt="logoSvg" className="logoSvgImg" />
                        </Box>
                    </Grid>
                    <Grid className="container">
                        <Box className="title">What are your listening preferences?</Box>
                        <Box className="secTitle">Select five categories</Box>
                        <Grid className="mainContainer">
                            <Box className="containerTitle">Categories</Box>
                            <Grid className="boxContainer">                                
                              {
                                this.state.data.map((item)=>(
                                     <Grid key={item.id} data-test-id ="boxSelectedimg" onClick={() => this.handleBoxes(item)}>
                                            <Box className="boxContain">
                                                <Box className={`boxInside ${ResolveCondition(this.state.selectedId.includes(Number(item.id)), "boxSelected", "")}
                                              `}>
                                                    <img src={item.attributes.image.url} alt={item.attributes.name} className="img" />
                                                </Box>
                                                <div className="label">{item.attributes.name}</div>
                                            </Box>
                                        </Grid>
                                ))
                              }
                            </Grid>
                            <Button className={`btnSignUp ${ResolveCondition(this.state.selectedId.length > 0 , "btnSelectedSignUp" , "")}`} style={{ color: "white",textTransform:"capitalize"}} disabled={this.state.selectedId.length <= 0} onClick={() =>{this.postApi()}} data-test-id="submit-btn">Sign Up</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </StyledWrapperCategories>
            </>
        );
    }

}
const StyledWrapperCategories = styled("div")({
    margin: "0 auto",
    height: "auto",
    width: "100%",
    background: "black",
    "& *": {
        boxSizing: "border-box",
    },
    "& .mainDiv": {
        background: "black"
    },
    "& .btnSignUp": {
        cursor: "pointer",
        width: "60%",
        height: "4%",
        background: "#B2B3B3",
        borderRadius: "8px",
        color: "white",
        margin: "auto",
        display: "flex",
        marginTop: "3%",
        marginBottom: "3%",
        fontFamily: "Poppins",
        fontWeight: 900,
        fontSize: "14px",
        "&:hover": {
            background: "#B2B3B3",
            color: "white"
        },
    },
    "& .btnSelectedSignUp": {
        background: "#FF0807",
    },
    "& .boxContainer": {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center"
    },
    "& .img": {
        height: "40%",
        width: "40%"
    },
    "& .label": {
        fontFamily: "Poppins",
        fontSize: "18px",
        fontWeight: 900,
        textAlign: "center",
        padding: "6% 14%"
    },
    "& .boxInside": {
        background: "#44403C",
        height: "223px",
        width: "239px",
        borderRadius: "39px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer"
    },
    "& .boxSelected": {
        background: "#FF0807"
    },
    "& .boxContain": {
        background: "#312F2D",
        height: "289px",
        width: "239px",
        borderRadius: "16px",
        margin: "8px"
    },
    "& .containerTitle": {
        fontFamily: "Poppins",
        fontWeight: 900,
        fontSize: "20px",
        paddingLeft: "1%",
        paddingBottom: "1%"
    },
    "& .mainContainer": {
        width: "70%",
        color: "white",
        margin: "auto",
        marginTop: "1%",
        marginBottom: "3%"
    },
    "& .title": {
        textAlign: "center",
        fontFamily: "Poppins",
        fontWeight: 900,
        fontSize: "18px",
        color: "#FF0807",
        textTransform: "uppercase"
    },
    "& .secTitle": {
        color: "white",
        textTransform: "uppercase",
        fontSize: "28px",
        fontWeight: 900,
        textAlign: "center",
        paddingTop: "5px",
        fontFamily: "Poppins"
    },
    "& .container": {
        height: "auto",
        marginTop: "3%",
    },
    "& .logoSvgImg": {
        height: "100%",
        width: "100%"
    },
    "& .imageContainer": {
        position: "relative",
        width: "100%",
    },
    "& .backCategories": {
        width: "100%",
        height: "auto",
    },
    "& .backCategoriesImage": {
        width: "100%",
        height: "auto",
        objectFit: "cover",
    },
    "& .logoSvg": {
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translateX(-50%)",
        width: "20vw",
        height: "auto",
        display: "flex",
        justifyContent: "center",
    },

})

// Customizable Area End
