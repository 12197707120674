import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import mainLogo  from "./image_logo.png";
import { Host, Partner, Resident, Notifications, Settings, SearchIcon } from "./icons.js";

const TopNav = () => {
  const navigate = useNavigate()
  const [activeIcon, setActiveIcon] = useState("Resident")
  const [searchTerm, setSearchTerm] = useState("")

  const handleIconClick = (icon, path) => {
    setActiveIcon((prev) => (prev === icon ? null : icon))
    handleNavigate(path)
  }

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value)
  }

  const handleNavigate = (path) => {
    navigate(path)
  }
  
  return (
    <div style={styles.main}>
      <div style={{...styles.iconsWrapper, width: "50%", paddingRight: "5px"}}>
        <img
          style={styles.logoImg}
          onClick={() => handleNavigate("/")}
          src={mainLogo} 
          alt="logo" />
        <Resident
          isActive={activeIcon === "Resident"}
          callback={() => handleIconClick("Resident", "/")}
        />
        <Host
          isActive={activeIcon === "Host"}
          callback={() => handleIconClick("Host", "/")}
        />
        <Partner
          isActive={activeIcon === "Partner"}
          callback={() => handleIconClick("Partner", "/")}
        />
      </div>
      <div style={{...styles.iconsWrapper, width: "50%"}}>
        <div style={styles.searchWrapper}>
          <SearchIcon  />
          <input
            value={searchTerm}
            onChange={handleSearchChange} 
            style={styles.searchInput}
            type="search" 
            placeholder="Search" />
        </div>
        <div style={styles.notifyIcons}>
          <Notifications />
          <Settings />
        </div>
      </div>
    </div>
  );
};

const styles = {
  main: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "#030303",
    gap: "65px",
    width: 'calc(100% - 135px -3%)',
    height: "65px",
    paddingTop: "45px",
    paddingBottom: "40px",
    paddingRight: "5%",
    paddingLeft: "5%"
  },
  iconsWrapper: {
    display: 'flex',
    justifyContent: "space-between",
    alignItems: "center",
    gap: 20,
  },
  searchWrapper: {
    display: "flex",
    alignItems: "center",
    flex: 1,
    position: 'relative'
  },
  searchInput: {
    padding: "10px 8px 10px 8px",
    border: "1px solid white",
    borderRadius: "98px",
    backgroundColor: "#030303",
    color: "white",
    outline: "none",
    flex: 7,
    paddingLeft: "35px",
  },
  notifyIcons: {
    display: 'flex',
    alignItems: 'center',
    gap: 20,
  },
  logoImg: {
    width: "148px",
    height: "65px" ,
    cursor: "pointer"
  }
};

export default TopNav;
