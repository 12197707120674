import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import micro  from "./micro.png";
import { Dashboard, Discover, Library, Playlist, Social } from "./icons"
import Avatar from '@mui/material/Avatar';
import { FmdGood, PersonOutline, Settings, SubdirectoryArrowRight }from '@mui/icons-material'

const PADDING_TOP = window.innerHeight > 900 ? "50px" : "5px"
const NARROW_SCREEN = window.innerHeight < 900

const Sidebar = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [activeIcon, setActiveIcon] = useState('Dashboard')
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isOffline, setIsOffline] = useState(!navigator.onLine)
  const accountDescription = "Settings Related To Your Personal Information And Account Credentials"
  const userDescription = "Digital copywriter at Digital media Agency. I love sci-fi movies and cats."

  useEffect(() => {
    const handleOnlineStatus = () => setIsOffline(!navigator.onLine)
    window.addEventListener("online", handleOnlineStatus)
    window.addEventListener("offline", handleOnlineStatus)

    if (activeIcon && location.pathname !== `/${activeIcon}`) {
      setActiveIcon(null)
    }

    return () => {
      window.removeEventListener("online", handleOnlineStatus)
      window.removeEventListener("offline", handleOnlineStatus)
    }
  }, [location.pathname, activeIcon])

  const handleIconClick = (icon, path) => {
    setActiveIcon(icon)
    if (path) {
      navigate(path)
    }
  }

  const handleProfilePhotoClick = () => {
    if (navigator.onLine) {
      setIsMenuOpen(true)
    } else {
      setIsOffline(true)
    }
  }

  const handleOverlayClick = () => {
    setIsMenuOpen(false)
  }
  
  return (
    <div style={styles.main}>
      <div style={styles.wrapper}>
        <img src={micro} width="69px" height="70px" alt="logo" />
        <div style={{padding: "35px 0"}}>
          <Avatar
            onClick={handleProfilePhotoClick}
            sx={{ width: 76, height: 76, cursor: "pointer" }} 
            src={"/broken-image.jpg"} />
        </div>
        <div style={styles.navItems}>
          <Dashboard  
            isActive={activeIcon === "Dashboard"}
            callback={() => handleIconClick("Dashboard", "/Dashboard")} />
          <Playlist
            isActive={activeIcon === "Playlist"}
            callback={() => handleIconClick("Playlist", "/Playlist")} />
          <Library 
            isActive={activeIcon === "Library"}
            callback={() => handleIconClick("Library","/Library")} />
          <Discover 
            isActive={activeIcon === "Discover"}
            callback={() => handleIconClick("Discover", "/Discover")} />
          <Social 
            isActive={activeIcon === "Social"}
            callback={() => handleIconClick("Social", "/Social")} />
        </div>
      </div>
      {isMenuOpen && (
        <div style={styles.modalOverlay} onClick={handleOverlayClick}>
          <div style={styles.modal} onClick={(e) => e.stopPropagation()}>
            <div style={styles.info}>
              <Avatar 
                sx={{ width: 132, height: 132, border: "4px solid #030303" }} 
                src={"/broken-image.jpg"} />
              <div style={styles.details}>
                <span style={styles.name}>Armin van Buuren</span>
                <div style={{display: "flex", alignItems: "center", gap: 4}}>
                  <FmdGood sx={{ color: "#fff" }} fontSize="small" />
                  <span style={styles.location}>London, UK</span>
                </div>
              </div>
              <div style={styles.description}>{userDescription}</div>
            </div>
            <div style={styles.settings}>
              <span style={styles.header}>Settings</span>
              <div style={styles.account}>
                <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
                  <PersonOutline />
                  <span style={styles.title}>Account</span>
                </div>
                <span style={styles.settingsDescription}>{accountDescription}</span>
              </div>
              <div style={{...styles.account, backgroundColor: "#44403C", marginTop: '10px'}}>
                <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
                  <Settings />
                  <span style={styles.title}>Settings</span>
                </div>
                <span style={styles.settingsDescription}>Access To Configurations</span>
              </div>
              <div style={{...styles.account, backgroundColor: "#44403C"}}>
                <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
                  <SubdirectoryArrowRight />
                  <span style={styles.title}>Log out</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const styles = {
  main: {
    backgroundColor: "#030303",
    borderRight: "1px solid #E2E8F0",
    width: "135px",
    height: "90vh"
  },
  wrapper:{
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",  
    paddingTop: "45px",
  },
  navItems: {
    display: "flex", 
    flexDirection: "column", 
    alignItems: "center", 
    gap: 20
  },
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: '136px',
    width: 'calc(100% - 136px)',
    height: '90vh',
    backdropFilter: 'blur(4px)',
    zIndex: 100,
    display: 'flex',
    justifyContent: 'start',
  },
  modal: {
    display: "flex",
    flexDirection: "column",
    width: "362px",
    backgroundColor: '#44403C',
    borderBottomRightRadius: "64px",
    borderLeft: "1px solid #030303"
  },
  info: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#312F2D",
    borderBottomRightRadius: "32px",
    paddingTop: PADDING_TOP,
    paddingBottom: "10px"
  },
  details: {
    display: "flex",
    alignItems: 'center',
    color: "#fff",
    flexDirection: "column",
    padding: "0 20px"
  },
  location: {
    paddingTop: "10px",
    paddingBottom: "10px",
    fontWeight: 400,
    fontSize: "16px",
    fontFamily: "Poppins",
  },
  description: {
    width: "327px",
    color: "#fff",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 900,
    textAlign: "center"
  },
  name: {
    textAlign: "center",
    width: "265px",
    fontWeight: 900,
    fontSize: NARROW_SCREEN ? "20px" : "30px",
    fontFamily: "Poppins",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  settings: {
    display: "flex",
    flexDirection: "column",
    color: "#fff",
    fontFamily: "Poppins",
    fontWeight: 900,
  },
  header: {
    backgroundColor: "#312F2D",
    margin: "10px 0",
    padding: "10px 20px",
    fontSize: NARROW_SCREEN ? "16px" : "20px",
  },
  account: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#030303",
    margin: "0 20px",
    padding: "15px 15px",
    borderRadius: "8px"
  },
  title: {
    fontSize: NARROW_SCREEN ? "15px" : "18px",
  },
  settingsDescription: {
    fontSize: "14px",
    width: "250px",
    paddingTop: "10px",
    paddingLeft: "25px"
  }
};

export default Sidebar;
