// Customizable Area Start
import React from "react";
import {
    Box,
    styled,
    Grid,
    TextField,
    Button,
} from "@mui/material";
import EmailForgotPasswordController, {
    Props,
} from "./EmailForgotPasswordController";
import { ForgotPasswordImg, logoSvg } from "./assets";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { handleNavigation } from "../../utilities/src/CustomHelpers";

export default class EmailForgotPassword extends EmailForgotPasswordController {
    constructor(props: Props) {
        super(props);
    }
    validationSchema = Yup.object().shape({

    });
    render() {
        return (
            <StyleWrapperSocialLink>
                <Grid className="container">
                    <Grid className="leftContainer">
                        <Formik
                            initialValues={{
                                facebook: "",
                                instagram: "",
                                x: "",
                                linkedin: ""
                            }}
                            data-test-id="formikWrapper"
                            onSubmit={(values) => {
                                handleNavigation("EmailRegistrationCategories", this.props)
                            }}
                        >
                            {({ handleChange, handleSubmit, values }) => (
                                <Form onSubmit={handleSubmit}>
                                    <Box className="logoImg">
                                        <img src={logoSvg} alt="logo" />
                                    </Box>
                                    <Box className="title">Forgot Password?</Box>
                                    <Box className="secTitle">Enter your email address below and we'll send you instructions to reset your password.</Box>

                                    <Grid className="fields">
                                        <Box>
                                            <label className="label">Enter your email address</label>
                                        </Box>
                                        <TextField
                                            id="outlined-basic"
                                            name="facebook"
                                            value={values.facebook}
                                            onChange={handleChange}
                                            sx={{
                                                marginTop: "4%",
                                                width: "100%",
                                                "& .MuiOutlinedInput-root": {
                                                    "& fieldset": {
                                                        borderColor: "#CBD5E1",
                                                    },
                                                    "&:hover fieldset": {
                                                        borderColor: "#CBD5E1",
                                                    },
                                                    "&.Mui-focused fieldset": {
                                                        borderColor: "#CBD5E1",
                                                    },
                                                },
                                                "& .MuiInputLabel-root": {
                                                    color: "#CBD5E1",
                                                },
                                                "& .MuiInputBase-input": {
                                                    color: "#CBD5E1",
                                                },
                                            }}
                                        />
                                    </Grid>

                                    <Button type="submit" className="btnContinue" data-test-id="submitBtn">
                                        <b>Send Link</b>
                                    </Button>
                                    <Box className="login"><b>You do not have an account?   <a href="EmailAccountRegistration" style={{ textDecoration: "none", color: "#1D4ED8", cursor: "pointer" }}>Sign in</a></b></Box>
                                </Form>
                            )}
                        </Formik>
                    </Grid>
                    <Grid className="rightContainer">
                        <img src={ForgotPasswordImg} alt="image_socialLinks" className="imageSignUp" />
                    </Grid>
                </Grid>
            </StyleWrapperSocialLink>
        );
    }

}
const StyleWrapperSocialLink = styled('div')({
    margin: "0 auto",
    height: "auto",
    width: "100%",
    background: "black",
    "& *": {
        boxSizing: "border-box",
    },
    "& .secTitle": {
        color: "white",
        fontFamily: "Poppins",
        fontSize: "12px",
        fontWeight: 100,
        textAlign: "center",
        textTransform : "capitalize",
        padding : "20px"
    },
    "& .login": {
        color: "white",
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "12px",
        textAlign: "center",
        marginTop: "4%",
    },
    "& .imageSignUp": {
        width: "100%",
        height: "auto",
        top: "20%"
    },
    "& .leftContainer": {
        padding: "3% 10%",
        width: "45%",
    },
    "& .btnSkip": {
        width: "100%",
        height: "6%",
        color: "white",
        background: "#312F2D",
        fontFamily: "Poppins",
        borderRadius: "8px",
        fontWeight: 900,
        fontSize: "18px",
        marginTop: "10%",
        textTransform: "capitalize",
        "&:hover": {
            background: "#312F2D",
            color: "white"
        },
    },
    "& .btnContinue": {
        width: "100%",
        height: "6%",
        color: "white",
        background: "#FF0807",
        fontFamily: "Poppins",
        borderRadius: "8px",
        fontWeight: 900,
        fontSize: "18px",
        marginTop: "5%",
        textTransform: "capitalize",
        "&:hover": {
            background: "#FF0807",
            color: "white"
        },
    },
    "& .logoImg": {
        justifyContent: "center",
        display: "flex",
        marginTop: "10%"
    },
    "& .label": {
        fontSize: "14px",
        color: "white",
        fontFamily: "Poppins",
        fontWeight: 900
    },
    "& .container": {
        width: "100%",
        height: "auto",
        backgroundColor: "black",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItens: "center"
    },
    "& .title": {
        fontWeight: 900,
        fontSize: "20px",
        color: "white",
        fontFamily: "Poppins",
        textAlign: "center",
        marginBottom: "4%",
        marginTop: "7%",
    },
    "& .fields": {
        marginTop: "4%"
    },
    "& .rightContainer": {
        display: "flex",
        width: "55%",
        justifyContent: "flex-start",
        paddingTop: "5%",
        alignItems: "flex-start",
    },
})
// Customizable Area End
