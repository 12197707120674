export const Resident = ({ isActive, callback }) => {
    const iconColor = isActive ? "#FF0807" : "white"

    return (
        <svg
        onClick={callback}
        style={{cursor: "pointer"}}
        width="80" 
        height="55" 
        viewBox="0 0 80 55" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg">
            <path 
            fillRule="evenodd" 
            clipRule="evenodd" 
            d="M40 4C37.79 4 36 5.79 36 8C36 10.21 37.79 12 40 12C42.21 12 44 10.21 44 8C44 5.79 42.21 4 40 4ZM42.1 8C42.1 6.84 41.16 5.9 40 5.9C38.84 5.9 37.9 6.84 37.9 8C37.9 9.16 38.84 10.1 40 10.1C41.16 10.1 42.1 9.16 42.1 8ZM46.1 17C46.1 16.36 42.97 14.9 40 14.9C37.03 14.9 33.9 16.36 33.9 17V18.1H46.1V17ZM32 17C32 14.34 37.33 13 40 13C42.67 13 48 14.34 48 17V19C48 19.55 47.55 20 47 20H33C32.45 20 32 19.55 32 19V17Z" 
            fill={iconColor}/>
            <path 
            d="M19.7421 42.832V43H17.3201L14.8141 39.78H13.2321V43H11.0481V33.2H15.6961C16.8254 33.2 17.7121 33.522 18.3561 34.166C19.0001 34.81 19.3221 35.5893
                19.3221 36.504C19.3127 37.232 19.1494 37.8527 18.8321 38.366C18.5147 38.87 17.9221 39.276 17.0541 39.584L19.7421 42.832ZM15.6961
                35.076H13.2321V37.932H15.7241C16.1907 37.932 16.5454 37.792 16.7881 37.512C17.0401 37.232 17.1661 36.9147 17.1661 36.56C17.1567 36.1773 17.0261
                35.8367 16.7741 35.538C16.5221 35.23 16.1627 35.076 15.6961 35.076ZM27.3468 40.2H22.2648C22.3208 40.5547 22.5121 40.8487 22.8388 41.082C23.1655
                41.306 23.5995 41.418 24.1408 41.418C24.9155 41.418 25.5128 41.236 25.9328 40.872L27.0668 42.132C26.3761 42.8133 25.3868 43.154 24.0988
                43.154C22.8668 43.154 21.9101 42.8227 21.2288 42.16C20.5568 41.4973 20.2208 40.6013 20.2208 39.472C20.2208 38.408 20.5521 37.5493 21.2148
                36.896C21.8775 36.2333 22.7688 35.902 23.8888 35.902C25.1115 35.902 26.0448 36.2753 26.6888 37.022C27.3421 37.7687 27.5615 38.828 27.3468 
                40.2ZM22.2648 38.73H25.5408C25.5221 38.3753 25.3541 38.1047 25.0368 37.918C24.7195 37.722 24.3368 37.624 23.8888 37.624C23.4875 37.624 23.1421 
                37.722 22.8528 37.918C22.5635 38.114 22.3675 38.3847 22.2648 38.73ZM34.0409 36.784L33.0469 38.044C32.5709 37.708 32.0249 37.54 31.4089 
                37.54C31.0915 37.54 30.8442 37.5913 30.6669 37.694C30.4989 37.7967 30.4149 37.9367 30.4149 38.114C30.4149 38.4967 30.7555 38.6973 31.4369 
                38.716C31.7449 38.7253 32.0202 38.7487 32.2629 38.786C32.5149 38.814 32.7809 38.8793 33.0609 38.982C33.3502 39.0753 33.5882 39.1967 33.7749
                39.346C33.9615 39.4953 34.1202 39.71 34.2509 39.99C34.3815 40.2607 34.4469 40.578 34.4469 40.942C34.4562 41.5767 34.2089 42.1227 33.7049 
                42.58C33.2009 43.0373 32.4402 43.266 31.4229 43.266C30.2002 43.266 29.1175 42.874 28.1749 42.09L29.0429 40.662C29.7242 41.222 30.5269 41.5113 
                31.4509 41.53C31.7495 41.5393 31.9969 41.488 32.1929 41.376C32.3982 41.264 32.4962 41.1193 32.4869 40.942C32.4869 40.5593 32.0949 40.354 31.3109 
                40.326C30.9655 40.3073 30.6295 40.2607 30.3029 40.186C29.9855 40.102 29.6775 39.9807 29.3789 39.822C29.0895 39.654 28.8562 39.4253 28.6789 
                39.136C28.5015 38.8373 28.4129 38.4967 28.4129 38.114C28.4129 37.3767 28.7022 36.8213 29.2809 36.448C29.8595 36.0747 30.5642 35.888 31.3949 
                35.888C32.4682 35.888 33.3502 36.1867 34.0409 36.784ZM37.6679 36.07V43H35.6519V36.07H37.6679ZM35.5119 34.25C35.5119 34.6047 35.6285 34.8847 
                35.8619 35.09C36.1045 35.2953 36.3705 35.398 36.6599 35.398C36.9492 35.398 37.2105 35.2953 37.4439 35.09C37.6865 34.8847 37.8079 34.6047 37.8079
                34.25C37.8079 33.8953 37.6865 33.6153 37.4439 33.41C37.2105 33.2047 36.9492 33.102 36.6599 33.102C36.3705 33.102 36.1045 33.2047 35.8619 
                33.41C35.6285 33.6153 35.5119 33.8953 35.5119 34.25ZM42.7026 37.764C42.1893 37.764 41.7786 37.932 41.4706 38.268C41.1626 38.5947 41.0086
                39.0193 41.0086 39.542C41.0086 40.0553 41.1579 40.48 41.4566 40.816C41.7646 41.152 42.1799 41.32 42.7026 41.32C43.2159 41.32 43.6546 41.1753 44.0186
                40.886C44.3826 40.5967 44.5646 40.1487 44.5646 39.542C44.5646 38.9913 44.3779 38.5573 44.0046 38.24C43.6313 37.9227 43.1973 37.764 42.7026
                37.764ZM44.5926 33.214H46.6226V43H44.8166L44.6906 42.118C44.1679 42.79 43.4026 43.126 42.3946 43.126C41.3773 43.126 40.5513 42.7993 39.9166 
                42.146C39.2913 41.4833 38.9786 40.6153 38.9786 39.542C38.9786 38.4407 39.2959 37.568 39.9306 36.924C40.5653 36.2707 41.3959 35.944 42.4226 
                35.944C43.3373 35.944 44.0606 36.2707 44.5926 36.924V33.214ZM55.0597 40.2H49.9777C50.0337 40.5547 50.225 40.8487 50.5517 41.082C50.8783 41.306
                51.3123 41.418 51.8537 41.418C52.6283 41.418 53.2257 41.236 53.6457 40.872L54.7797 42.132C54.089 42.8133 53.0997 43.154 51.8117 43.154C50.5797
                43.154 49.623 42.8227 48.9417 42.16C48.2697 41.4973 47.9337 40.6013 47.9337 39.472C47.9337 38.408 48.265 37.5493 48.9277 36.896C49.5903 36.2333
                50.4817 35.902 51.6017 35.902C52.8243 35.902 53.7577 36.2753 54.4017 37.022C55.055 37.7687 55.2743 38.828 55.0597 40.2ZM49.9777 
                38.73H53.2537C53.235 38.3753 53.067 38.1047 52.7497 37.918C52.4323 37.722 52.0497 37.624 51.6017 37.624C51.2003 37.624 50.855 37.722 50.5657 
                37.918C50.2763 38.114 50.0803 38.3847 49.9777 38.73ZM63.4198 43H61.3898V39.346C61.3898 38.2727 60.9138 37.736 59.9618 37.736C59.5044 37.7267 
                59.1264 37.89 58.8278 38.226C58.5384 38.562 58.3938 38.9633 58.3938 39.43V43H56.4058V36.084H58.2258L58.2958 36.966C58.8464 36.2473 59.5931 
                35.8973 60.5358 35.916C62.4584 35.916 63.4198 37.0547 63.4198 39.332V43ZM65.7607 34.418L67.7767
                34.194V36.126H69.5267V37.806H67.7627V40.41C67.7627 41.026 68.024 41.334 68.5467 41.334C68.8827 41.334 69.1627 41.278 69.3867 41.166L69.8487 
                42.846C69.3914 43.0233 68.906 43.126 68.3927 43.154C67.5807 43.1913 66.9367 43 66.4607 42.58C65.994 42.1507 65.7607 41.4273 65.7607 
                40.41V37.806H64.5287V36.126H65.7607V34.418Z" 
                fill={iconColor}/>
        </svg>
    )
}

export const Host = ({ isActive, callback }) => {
    const iconColor = isActive ? "#FF0807" : "white"

    return (
        <svg
        onClick={callback}
        style={{cursor: "pointer"}}
        width="107" 
        height="55" 
        viewBox="0 0 107 55" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg">
            <path 
            fillRule="evenodd" 
            clipRule="evenodd" 
            d="M46 4H60C61.11 4 62 4.9 62 6V18C62 19.1 61.1 20 60 20H46C44.89 20 44 19.1 44 18V6C44 4.9 44.89 4 46 4ZM47 18H59C59.55 18 60 17.55 60 17V8H46V17C46 17.55 46.45 18 47 18Z" 
            fill={iconColor}/>
            <path 
            d="M5.3883 38.828V41.096H8.0763C9.03763 41.096 9.5183 40.7227 9.5183 39.976C9.5183 39.6213 9.3923 39.3413 9.1403 39.136C8.8883 38.9307 8.5243 
            38.828 8.0483 38.828H5.3883ZM5.3883 35.09V37.008H8.0483C8.89763 37.008 9.3223 36.7 9.3223 36.084C9.3223 35.7853 9.21496 35.5473 9.0003 
            35.37C8.78563 35.1833 8.47763 35.09 8.0763 35.09H5.3883ZM3.2463 33.2H8.0763C9.06563 33.2 9.87763 33.4287 10.5123 33.886C11.1563 34.334 11.4783 
            34.992 11.4783 35.86C11.4783 36.7933 11.0443 37.4607 10.1763 37.862C10.7736 38.0767 11.2076 38.4547 11.4783 38.996C11.7583 39.5373 11.8423 
            40.1067 11.7303 40.704C11.4503 42.2347 10.2603 43 8.1603 43H3.2463V33.2ZM19.8458 40.2H14.7638C14.8198 40.5547 15.0111 40.8487 15.3378 
            41.082C15.6645 41.306 16.0985 41.418 16.6398 41.418C17.4145 41.418 18.0118 41.236 18.4318 40.872L19.5658 42.132C18.8751 42.8133 17.8858 43.154 
            16.5978 43.154C15.3658 43.154 14.4091 42.8227 13.7278 42.16C13.0558 41.4973 12.7198 40.6013 12.7198 39.472C12.7198 38.408 13.0511 37.5493 
            13.7138 36.896C14.3765 36.2333 15.2678 35.902 16.3878 35.902C17.6105 35.902 18.5438 36.2753 19.1878 37.022C19.8411 37.7687 20.0605 38.828 
            19.8458 40.2ZM14.7638 38.73H18.0398C18.0211 38.3753 17.8531 38.1047 17.5358 37.918C17.2185 37.722 16.8358 37.624 16.3878 37.624C15.9865 37.624 
            15.6411 37.722 15.3518 37.918C15.0625 38.114 14.8665 38.3847 14.7638 38.73ZM25.9939 40.774L27.2679 42.09C26.5492 42.7993 25.6486 43.154 24.5659 
            43.154C23.4832 43.154 22.5826 42.8507 21.8639 42.244C21.1452 41.6373 20.7859 40.7413 20.7859 39.556C20.7859 38.436 21.1499 37.554 21.8779 
            36.91C22.6059 36.2567 23.4972 35.93 24.5519 35.93C25.6159 35.93 26.4512 36.2473 27.0579 36.882L25.9379 38.156C25.5832 37.8853 25.1492 37.75 
            24.6359 37.75C24.1132 37.75 23.6792 37.9133 23.3339 38.24C22.9886 38.5573 22.8159 38.996 22.8159 39.556C22.8159 40.088 22.9886 40.5127 23.3339 
            40.83C23.6792 41.138 24.0992 41.292 24.5939 41.292C24.8366 41.292 25.0886 41.2453 25.3499 41.152C25.6206 41.0587 25.8352 40.9327 25.9939 
            40.774ZM31.4839 43.154C30.3359 43.154 29.4165 42.8227 28.7259 42.16C28.0445 41.488 27.7039 40.62 27.7039 39.556C27.7039 38.5013 28.0492 37.6333 
            28.7399 36.952C29.4399 36.2707 30.3545 35.93 31.4839 35.93C32.6225 35.93 33.5372 36.2707 34.2279 36.952C34.9279 37.6333 35.2779 38.5013 35.2779 
            39.556C35.2779 40.62 34.9325 41.488 34.2419 42.16C33.5605 42.8227 32.6412 43.154 31.4839 43.154ZM31.4839 41.362C32.0625 41.362 32.4965 41.1893 
            32.7859 40.844C33.0845 40.4893 33.2339 40.06 33.2339 39.556C33.2339 39.0707 33.0845 38.646 32.7859 38.282C32.4872 37.918 32.0532 37.736 31.4839 
            37.736C30.9239 37.736 30.4945 37.918 30.1959 38.282C29.8972 38.646 29.7479 39.0707 29.7479 39.556C29.7479 40.0413 29.8925 40.466 30.1819 
            40.83C30.4805 41.1847 30.9145 41.362 31.4839 41.362ZM43.0264 43H41.0384V39.29C41.0384 38.282 40.6418 37.778 39.8484 37.778C39.4658 37.778 
            39.1624 37.9273 38.9384 38.226C38.7144 38.5247 38.6024 38.884 38.6024 39.304V43H36.5864V36.084H38.3504L38.4904 36.868C38.8451 36.2427 39.4518 
            35.93 40.3104 35.93C41.2718 35.93 41.9438 36.3593 42.3264 37.218C42.6344 36.7513 42.9658 36.42 43.3204 36.224C43.6751 36.0187 44.1091 35.916 
            44.6224 35.916C45.4438 35.916 46.1064 36.168 46.6104 36.672C47.1238 37.176 47.3804 38.0253 47.3804 39.22V43H45.3504V39.262C45.3504 38.3007 
            45.0004 37.82 44.3004 37.82C43.9178 37.82 43.6098 37.9647 43.3764 38.254C43.1431 38.534 43.0264 38.8793 43.0264 39.29V43ZM55.7208 
            40.2H50.6388C50.6948 40.5547 50.8861 40.8487 51.2128 41.082C51.5395 41.306 51.9735 41.418 52.5148 41.418C53.2895 41.418 53.8868 41.236 54.3068 
            40.872L55.4408 42.132C54.7501 42.8133 53.7608 43.154 52.4728 43.154C51.2408 43.154 50.2841 42.8227 49.6028 42.16C48.9308 41.4973 48.5948 40.6013 
            48.5948 39.472C48.5948 38.408 48.9261 37.5493 49.5888 36.896C50.2515 36.2333 51.1428 35.902 52.2628 35.902C53.4855 35.902 54.4188 36.2753 
            55.0628 37.022C55.7161 37.7687 55.9355 38.828 55.7208 40.2ZM50.6388 38.73H53.9148C53.8961 38.3753 53.7281 38.1047 53.4108 37.918C53.0935 37.722 
            52.7108 37.624 52.2628 37.624C51.8615 37.624 51.5161 37.722 51.2268 37.918C50.9375 38.114 50.7415 38.3847 50.6388 38.73ZM65.6672 36.882L65.7372 
            36.084H67.6272V43H65.8072L65.6952 42.132C65.4805 42.468 65.1678 42.72 64.7572 42.888C64.3558 43.056 63.9592 43.1447 63.5672 43.154C62.5125 
            43.1633 61.6538 42.846 60.9912 42.202C60.3285 41.5487 59.9972 40.662 59.9972 39.542C59.9972 38.422 60.3378 37.5353 61.0192 36.882C61.7098 
            36.2287 62.5778 35.9113 63.6232 35.93C64.0712 35.93 64.4772 36.014 64.8412 36.182C65.2145 36.35 65.4898 36.5833 65.6672 36.882ZM63.8332 
            37.708C63.3105 37.708 62.8765 37.876 62.5312 38.212C62.1858 38.5387 62.0132 38.982 62.0132 39.542C62.0132 40.102 62.1858 40.5453 62.5312 
            40.872C62.8765 41.1987 63.3105 41.362 63.8332 41.362C64.3745 41.362 64.8132 41.1847 65.1492 40.83C65.4945 40.4753 65.6672 40.0693 65.6672 
            39.612C65.6672 39.08 65.5038 38.632 65.1772 38.268C64.8598 37.8947 64.4118 37.708 63.8332 37.708ZM81.6128 
            43.014H79.4568V39.136H75.0748V43.014H72.8908V33.214H75.0748V37.134H79.4568V33.214H81.6128V43.014ZM86.7729 43.154C85.6249 43.154 84.7056 42.8227 
            84.0149 42.16C83.3336 41.488 82.9929 40.62 82.9929 39.556C82.9929 38.5013 83.3383 37.6333 84.0289 36.952C84.7289 36.2707 85.6436 35.93 86.7729 
            35.93C87.9116 35.93 88.8263 36.2707 89.5169 36.952C90.2169 37.6333 90.5669 38.5013 90.5669 39.556C90.5669 40.62 90.2216 41.488 89.5309 
            42.16C88.8496 42.8227 87.9303 43.154 86.7729 43.154ZM86.7729 41.362C87.3516 41.362 87.7856 41.1893 88.0749 40.844C88.3736 40.4893 88.5229 
            40.06 88.5229 39.556C88.5229 39.0707 88.3736 38.646 88.0749 38.282C87.7763 37.918 87.3423 37.736 86.7729 37.736C86.2129 37.736 85.7836 37.918 
            85.4849 38.282C85.1863 38.646 85.0369 39.0707 85.0369 39.556C85.0369 40.0413 85.1816 40.466 85.4709 40.83C85.7696 41.1847 86.2036 41.362 86.7729 
            41.362ZM97.2235 36.784L96.2295 38.044C95.7535 37.708 95.2075 37.54 94.5915 37.54C94.2742 37.54 94.0268 37.5913 93.8495 37.694C93.6815 37.7967 
            93.5975 37.9367 93.5975 38.114C93.5975 38.4967 93.9382 38.6973 94.6195 38.716C94.9275 38.7253 95.2028 38.7487 95.4455 38.786C95.6975 38.814 
            95.9635 38.8793 96.2435 38.982C96.5328 39.0753 96.7708 39.1967 96.9575 39.346C97.1442 39.4953 97.3028 39.71 97.4335 39.99C97.5642 40.2607 
            97.6295 40.578 97.6295 40.942C97.6388 41.5767 97.3915 42.1227 96.8875 42.58C96.3835 43.0373 95.6228 43.266 94.6055 43.266C93.3828 43.266 
            92.3002 42.874 91.3575 42.09L92.2255 40.662C92.9068 41.222 93.7095 41.5113 94.6335 41.53C94.9322 41.5393 95.1795 41.488 95.3755 41.376C95.5808 
            41.264 95.6788 41.1193 95.6695 40.942C95.6695 40.5593 95.2775 40.354 94.4935 40.326C94.1482 40.3073 93.8122 40.2607 93.4855 40.186C93.1682 
            40.102 92.8602 39.9807 92.5615 39.822C92.2722 39.654 92.0388 39.4253 91.8615 39.136C91.6842 38.8373 91.5955 38.4967 91.5955 38.114C91.5955 
            37.3767 91.8848 36.8213 92.4635 36.448C93.0422 36.0747 93.7468 35.888 94.5775 35.888C95.6508 35.888 96.5328 36.1867 97.2235 36.784ZM99.5625 
            34.418L101.578 34.194V36.126H103.328V37.806H101.564V40.41C101.564 41.026 101.826 41.334 102.348 41.334C102.684 41.334 102.964 41.278 103.188 
            41.166L103.65 42.846C103.193 43.0233 102.708 43.126 102.194 43.154C101.382 43.1913 100.738 43 100.262 42.58C99.7958 42.1507 99.5625 41.4273 
            99.5625 40.41V37.806H98.3305V36.126H99.5625V34.418Z" 
            fill={iconColor}/>
        </svg>
    )
}

export const Partner = ({ isActive, callback }) => {
    const iconColor = isActive ? "#FF0807" : "white"

    return (
        <svg
        onClick={callback}
        style={{cursor: "pointer"}}
        width="128" 
        height="55" 
        viewBox="0 0 128 55" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg">
            <path
            fillRule="evenodd" 
            clipRule="evenodd" 
            d="M70 11.5C71.66 11.5 72.99 10.16 72.99 8.5C72.99 6.84 71.66 5.5 70 5.5C69.68 5.5 69.37 5.55 69.09 5.64C69.66 6.45 69.99 7.43 69.99 8.5C69.99 
            9.57 69.65 10.54 69.09 11.36C69.37 11.45 69.68 11.5 70 11.5ZM59 10.5H57V8.5C57 7.95 56.55 7.5 56 7.5C55.45 7.5 55 7.95 55 8.5V10.5H53C52.45 
            10.5 52 10.95 52 11.5C52 12.05 52.45 12.5 53 12.5H55V14.5C55 15.05 55.45 15.5 56 15.5C56.55 15.5 57 15.05 57 14.5V12.5H59C59.55 12.5 60 12.05 
            60 11.5C60 10.95 59.55 10.5 59 10.5ZM67.99 8.5C67.99 10.16 66.66 11.5 65 11.5C63.34 11.5 62 10.16 62 8.5C62 6.84 63.34 5.5 65 5.5C66.66 5.5 
            67.99 6.84 67.99 8.5ZM65 13.5C63 13.5 59 14.5 59 16.5V17.5C59 18.05 59.45 18.5 60 18.5H70C70.55 18.5 71 18.05 71 17.5V16.5C71 14.5 67 13.5 65 
            13.5ZM73 16.5C73 15.32 72.45 14.39 71.62 13.66C73.63 14.01 76 14.96 76 16.5V18C76 18.28 75.78 18.5 75.5 18.5H72.95C72.98 18.34 73 18.17 73 
            18V16.5Z" 
            fill={iconColor}/>
            <path 
            d="M4.65099 38.828V41.096H7.33899C8.30033 41.096 8.78099 40.7227 8.78099 39.976C8.78099 39.6213 8.65499 39.3413 8.40299 39.136C8.15099 38.9307 
            7.78699 38.828 7.31099 38.828H4.65099ZM4.65099 35.09V37.008H7.31099C8.16033 37.008 8.58499 36.7 8.58499 36.084C8.58499 35.7853 8.47766 35.5473 
            8.26299 35.37C8.04833 35.1833 7.74033 35.09 7.33899 35.09H4.65099ZM2.50899 33.2H7.33899C8.32833 33.2 9.14033 33.4287 9.77499 33.886C10.419 
            34.334 10.741 34.992 10.741 35.86C10.741 36.7933 10.307 37.4607 9.43899 37.862C10.0363 38.0767 10.4703 38.4547 10.741 38.996C11.021 39.5373 
            11.105 40.1067 10.993 40.704C10.713 42.2347 9.52299 43 7.42299 43H2.50899V33.2ZM19.1085 40.2H14.0265C14.0825 40.5547 14.2738 40.8487 14.6005 
            41.082C14.9272 41.306 15.3612 41.418 15.9025 41.418C16.6772 41.418 17.2745 41.236 17.6945 40.872L18.8285 42.132C18.1378 42.8133 17.1485 43.154 
            15.8605 43.154C14.6285 43.154 13.6718 42.8227 12.9905 42.16C12.3185 41.4973 11.9825 40.6013 11.9825 39.472C11.9825 38.408 12.3138 37.5493 
            12.9765 36.896C13.6392 36.2333 14.5305 35.902 15.6505 35.902C16.8732 35.902 17.8065 36.2753 18.4505 37.022C19.1038 37.7687 19.3232 38.828 
            19.1085 40.2ZM14.0265 38.73H17.3025C17.2838 38.3753 17.1158 38.1047 16.7985 37.918C16.4812 37.722 16.0985 37.624 15.6505 37.624C15.2492 37.624 
            14.9038 37.722 14.6145 37.918C14.3252 38.114 14.1292 38.3847 14.0265 38.73ZM25.2566 40.774L26.5306 42.09C25.8119 42.7993 24.9113 43.154 23.8286 
            43.154C22.7459 43.154 21.8453 42.8507 21.1266 42.244C20.4079 41.6373 20.0486 40.7413 20.0486 39.556C20.0486 38.436 20.4126 37.554 21.1406 
            36.91C21.8686 36.2567 22.7599 35.93 23.8146 35.93C24.8786 35.93 25.7139 36.2473 26.3206 36.882L25.2006 38.156C24.8459 37.8853 24.4119 37.75 
            23.8986 37.75C23.3759 37.75 22.9419 37.9133 22.5966 38.24C22.2513 38.5573 22.0786 38.996 22.0786 39.556C22.0786 40.088 22.2513 40.5127 22.5966 
            40.83C22.9419 41.138 23.3619 41.292 23.8566 41.292C24.0993 41.292 24.3513 41.2453 24.6126 41.152C24.8833 41.0587 25.0979 40.9327 25.2566 
            40.774ZM30.7466 43.154C29.5986 43.154 28.6792 42.8227 27.9886 42.16C27.3072 41.488 26.9666 40.62 26.9666 39.556C26.9666 38.5013 27.3119 37.6333 
            28.0026 36.952C28.7026 36.2707 29.6172 35.93 30.7466 35.93C31.8852 35.93 32.7999 36.2707 33.4906 36.952C34.1906 37.6333 34.5406 38.5013 34.5406 
            39.556C34.5406 40.62 34.1952 41.488 33.5046 42.16C32.8232 42.8227 31.9039 43.154 30.7466 43.154ZM30.7466 41.362C31.3252 41.362 31.7592 41.1893 
            32.0486 40.844C32.3472 40.4893 32.4966 40.06 32.4966 39.556C32.4966 39.0707 32.3472 38.646 32.0486 38.282C31.7499 37.918 31.3159 37.736 30.7466 
            37.736C30.1866 37.736 29.7572 37.918 29.4586 38.282C29.1599 38.646 29.0106 39.0707 29.0106 39.556C29.0106 40.0413 29.1552 40.466 29.4446 
            40.83C29.7432 41.1847 30.1772 41.362 30.7466 41.362ZM42.2891 43H40.3011V39.29C40.3011 38.282 39.9045 37.778 39.1111 37.778C38.7285 37.778 
            38.4251 37.9273 38.2011 38.226C37.9771 38.5247 37.8651 38.884 37.8651 39.304V43H35.8491V36.084H37.6131L37.7531 36.868C38.1078 36.2427 38.7145 
            35.93 39.5731 35.93C40.5345 35.93 41.2065 36.3593 41.5891 37.218C41.8971 36.7513 42.2285 36.42 42.5831 36.224C42.9378 36.0187 43.3718 35.916 
            43.8851 35.916C44.7065 35.916 45.3691 36.168 45.8731 36.672C46.3865 37.176 46.6431 38.0253 46.6431 39.22V43H44.6131V39.262C44.6131 38.3007 
            44.2631 37.82 43.5631 37.82C43.1805 37.82 42.8725 37.9647 42.6391 38.254C42.4058 38.534 42.2891 38.8793 42.2891 39.29V43ZM54.9835 
            40.2H49.9015C49.9575 40.5547 50.1488 40.8487 50.4755 41.082C50.8022 41.306 51.2362 41.418 51.7775 41.418C52.5522 41.418 53.1495 41.236 53.5695 
            40.872L54.7035 42.132C54.0128 42.8133 53.0235 43.154 51.7355 43.154C50.5035 43.154 49.5468 42.8227 48.8655 42.16C48.1935 41.4973 47.8575 40.6013 
            47.8575 39.472C47.8575 38.408 48.1888 37.5493 48.8515 36.896C49.5142 36.2333 50.4055 35.902 51.5255 35.902C52.7482 35.902 53.6815 36.2753 
            54.3255 37.022C54.9788 37.7687 55.1982 38.828 54.9835 40.2ZM49.9015 38.73H53.1775C53.1588 38.3753 52.9908 38.1047 52.6735 37.918C52.3562 37.722 
            51.9735 37.624 51.5255 37.624C51.1242 37.624 50.7788 37.722 50.4895 37.918C50.2002 38.114 50.0042 38.3847 49.9015 38.73ZM67.5479 43L66.8339 
            41.32H61.8079L61.0939 43H58.7419L63.1379 33.2H65.5179L69.9139 43H67.5479ZM66.0079 39.388L64.3279 35.412L62.6339 39.388H66.0079ZM76.0055 
            35.272V38.226H78.4415C78.9455 38.226 79.3328 38.086 79.6035 37.806C79.8742 37.526 80.0095 37.176 80.0095 36.756C80.0095 36.336 79.8695 35.986 
            79.5895 35.706C79.3188 35.4167 78.9362 35.272 78.4415 35.272H76.0055ZM78.4415 40.228H76.0055V43H73.8215V33.2H78.4415C79.6362 33.2 80.5602 
            33.536 81.2135 34.208C81.8668 34.88 82.1935 35.7153 82.1935 36.714C82.1935 37.7407 81.8668 38.5807 81.2135 39.234C80.5695 39.8873 79.6455 
            40.2187 78.4415 40.228ZM88.4865 36.882L88.5565 36.084H90.4465V43H88.6265L88.5145 42.132C88.2998 42.468 87.9872 42.72 87.5765 42.888C87.1752 
            43.056 86.7785 43.1447 86.3865 43.154C85.3318 43.1633 84.4732 42.846 83.8105 42.202C83.1478 41.5487 82.8165 40.662 82.8165 39.542C82.8165 38.422 
            83.1572 37.5353 83.8385 36.882C84.5292 36.2287 85.3972 35.9113 86.4425 35.93C86.8905 35.93 87.2965 36.014 87.6605 36.182C88.0338 36.35 88.3092 
            36.5833 88.4865 36.882ZM86.6525 37.708C86.1298 37.708 85.6958 37.876 85.3505 38.212C85.0052 38.5387 84.8325 38.982 84.8325 39.542C84.8325 40.102 
            85.0052 40.5453 85.3505 40.872C85.6958 41.1987 86.1298 41.362 86.6525 41.362C87.1938 41.362 87.6325 41.1847 87.9685 40.83C88.3138 40.4753 
            88.4865 40.0693 88.4865 39.612C88.4865 39.08 88.3232 38.632 87.9965 38.268C87.6792 37.8947 87.2312 37.708 86.6525 
            37.708ZM92.1499 36.098H94.0259L94.1799 36.854C94.6186 36.238 95.2346 35.93 96.0279 35.93C96.6812 35.93 97.2459 36.14 97.7219 36.56L96.8819 
            38.156C96.5552 37.9227 96.1866 37.806 95.7759 37.806C95.3186 37.806 94.9359 37.9507 94.6279 38.24C94.3199 38.52 94.1659 38.9073 94.1659 
            39.402V43H92.1499V36.098ZM99.2353 34.418L101.251 34.194V36.126H103.001V37.806H101.237V40.41C101.237 41.026 101.499 41.334 102.021 
            41.334C102.357 41.334 102.637 41.278 102.861 41.166L103.323 42.846C102.866 43.0233 102.381 43.126 101.867 43.154C101.055 43.1913 100.411 43 
            99.9353 42.58C99.4687 42.1507 99.2353 41.4273 99.2353 40.41V37.806H98.0033V36.126H99.2353V34.418ZM111.387 43H109.357V39.346C109.357 38.2727 
            108.881 37.736 107.929 37.736C107.471 37.7267 107.093 37.89 106.795 38.226C106.505 38.562 106.361 38.9633 106.361 
            39.43V43H104.373V36.084H106.193L106.263 36.966C106.813 36.2473 107.56 35.8973 108.503 35.916C110.425 35.916 111.387 37.0547 111.387 
            39.332V43ZM119.734 40.2H114.652C114.708 40.5547 114.899 40.8487 115.226 41.082C115.552 41.306 115.986 41.418 116.528 41.418C117.302 41.418 117.9 
            41.236 118.32 40.872L119.454 42.132C118.763 42.8133 117.774 43.154 116.486 43.154C115.254 43.154 114.297 42.8227 113.616 42.16C112.944 41.4973 
            112.608 40.6013 112.608 39.472C112.608 38.408 112.939 37.5493 113.602 36.896C114.264 36.2333 115.156 35.902 116.276 35.902C117.498 35.902 
            118.432 36.2753 119.076 37.022C119.729 37.7687 119.948 38.828 119.734 40.2ZM114.652 38.73H117.928C117.909 38.3753 117.741 38.1047 117.424 
            37.918C117.106 37.722 116.724 37.624 116.276 37.624C115.874 37.624 115.529 37.722 115.24 37.918C114.95 38.114 114.754 38.3847 114.652 
            38.73ZM121.08 36.098H122.956L123.11 36.854C123.548 36.238 124.164 35.93 124.958 35.93C125.611 35.93 126.176 36.14 126.652 36.56L125.812 
            38.156C125.485 37.9227 125.116 37.806 124.706 37.806C124.248 37.806 123.866 37.9507 123.558 38.24C123.25 38.52 123.096 38.9073 123.096 
            39.402V43H121.08V36.098Z" 
            fill={iconColor}/>
        </svg>
    )
}

export const Notifications = () => {
    return(
        <svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M28.9358 26.06L27.0008 24.125V16.625C27.0008 12.02 24.5408 8.165 20.2508 7.145V6.125C20.2508 4.88 19.2458 3.875 18.0008 3.875C16.7558 3.875 15.7508 4.88 15.7508 6.125V7.145C11.4458 8.165 9.00081 12.005 9.00081 16.625V24.125L7.06581 26.06C6.12081 27.005 6.78081 28.625 8.11581 28.625H27.8708C29.2208 28.625 29.8808 27.005 28.9358 26.06ZM12.0008 25.625V16.625C12.0008 12.905 14.2658 9.875 18.0008 9.875C21.7358 9.875 24.0008 12.905 24.0008 16.625V25.625H12.0008ZM21.0008 30.125C21.0008 31.775 19.6508 33.125 18.0008 33.125C16.3358 33.125 15.0008 31.775 15.0008 30.125H21.0008Z" fill="white"/>
        </svg>
    )
}

export const Settings = () => {
    return(
        <svg width="63" height="37" viewBox="0 0 63 37" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M30 6.5H6C4.35 6.5 3.015 7.85 3.015 9.5L3 27.5C3 29.15 4.35 30.5 6 30.5H30C31.65 30.5 33 29.15 33 27.5V9.5C33 7.85 31.65 6.5 30 6.5ZM30 26C30 26.825 29.325 27.5 28.5 27.5H7.5C6.675 27.5 6 26.825 6 26V12.5L16.41 19.01C17.385 19.625 18.615 19.625 19.59 19.01L30 12.5V26ZM6 9.5L18 17L30 9.5H6Z" fill="white"/>
            <path d="M40.058 17.6207L43.943 21.5057C44.528 22.0907 45.473 22.0907 46.058 21.5057L49.943 17.6207C50.888 16.6757 50.213 15.0557 48.878 15.0557H41.108C39.773 15.0557 39.113 16.6757 40.058 17.6207Z" fill="white"/>
        </svg>
    )
}

export const SearchIcon = () => {
    return(
        <svg
        style={{ position: 'absolute', left: "7px", top: "8px"}}
        width="24" 
        height="25" 
        viewBox="0 0 24 25" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg">
            <path 
            fillRule="evenodd" 
            clipRule="evenodd" 
            d="M15.1872 14.9713H15.9772L20.2172 19.2313C20.6272 19.6413 20.6272 20.3113 20.2172 20.7213C19.8072 21.1313 19.1372 21.1313 18.7272 
            20.7213L14.4772 16.4713V15.6813L14.2072 15.4013C12.8072 16.6013 10.8972 17.2213 8.86719 16.8813C6.08719 16.4113 3.86719 14.0913 3.52719 
            11.2913C3.00719 7.06133 6.56719 3.50133 10.7972 4.02133C13.5972 4.36133 15.9172 6.58133 16.3872 9.36133C16.7272 11.3913 16.1072 13.3013 
            14.9072 14.7013L15.1872 14.9713ZM5.47719 10.4713C5.47719 12.9613 7.48719 14.9713 9.97719 14.9713C12.4672 14.9713 14.4772 12.9613 14.4772 
            10.4713C14.4772 7.98133 12.4672 5.97133 9.97719 5.97133C7.48719 5.97133 5.47719 7.98133 5.47719 10.4713Z" 
            fill="white"/>
        </svg>
    )
}

export const Social = ({ isActive, callback }) => {
    const iconColor = isActive ? "#FF0807" : "white"

    return(
        <svg 
        onClick={callback}
        style={{ cursor: "pointer" }}
        width="80" 
        height="52" 
        viewBox="0 0 80 52" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg">
            <path 
                d="M25.5451 43.6127C25.5053 43.2116 25.3346 42.9001 25.033 42.678C24.7314 42.4559 24.3221 42.3449 23.805 42.3449C23.4537 42.3449 23.1571 42.3946
                22.9151 42.4941C22.6732 42.5902 22.4876 42.7244 22.3583 42.8968C22.2324 43.0691 22.1694 43.2646 22.1694 43.4834C22.1628 43.6657 22.2009 43.8248 
                22.2837 43.9607C22.3699 44.0966 22.4876 44.2142 22.6367 44.3137C22.7859 44.4098 22.9582 44.4943 23.1538 44.5672C23.3493 44.6368 23.5581 44.6965 
                23.7802 44.7462L24.695 44.9649C25.1391 45.0644 25.5468 45.1969 25.918 45.3627C26.2892 45.5284 26.6107 45.7322 26.8825 45.9742C27.1542 46.2161 
                27.3647 46.5012 27.5138 46.8293C27.6663 47.1574 27.7442 47.5336 27.7475 47.9578C27.7442 48.5809 27.5851 49.1212 27.2702 49.5786C26.9587 50.0326 
                26.5079 50.3856 25.918 50.6375C25.3313 50.8861 24.6237 51.0104 23.7951 51.0104C22.9731 51.0104 22.2572 50.8844 21.6474 50.6325C21.0408 50.3807 
                20.5669 50.0078 20.2255 49.5139C19.8874 49.0168 19.7101 48.402 19.6935 47.6695H21.7766C21.7998 48.0109 21.8976 48.2959 22.07 48.5246C22.2456 
                48.75 22.4793 48.9207 22.771 49.0367C23.0659 49.1494 23.399 49.2057 23.7702 49.2057C24.1348 49.2057 24.4513 49.1527 24.7198 49.0466C24.9916 
                48.9405 25.2021 48.7931 25.3512 48.6041C25.5004 48.4152 25.5749 48.1981 25.5749 47.9529C25.5749 47.7242 25.507 47.5319 25.3711 47.3762C25.2385 
                47.2204 25.043 47.0878 24.7844 46.9784C24.5292 46.8691 24.216 46.7696 23.8448 46.6801L22.7362 46.4017C21.8777 46.1929 21.1999 45.8664 20.7028 
                45.4223C20.2056 44.9782 19.9587 44.3799 19.962 43.6276C19.9587 43.0111 20.1228 42.4725 20.4542 42.0118C20.7889 41.5511 21.248 41.1915 21.8313 
                40.933C22.4147 40.6744 23.0775 40.5452 23.82 40.5452C24.5756 40.5452 25.2352 40.6744 25.7987 40.933C26.3654 41.1915 26.8062 41.5511 27.1211 
                42.0118C27.436 42.4725 27.5984 43.0061 27.6083 43.6127H25.5451ZM32.606 51.0154C31.8337 51.0154 31.1659 50.8513 30.6025 50.5232C30.0423 50.1917 
                29.6098 49.731 29.3049 49.1411C28.9999 48.5478 28.8475 47.8601 28.8475 47.0779C28.8475 46.289 28.9999 45.5996 29.3049 45.0097C29.6098 44.4164 
                30.0423 43.9557 30.6025 43.6276C31.1659 43.2961 31.8337 43.1304 32.606 43.1304C33.3783 43.1304 34.0444 43.2961 34.6046 43.6276C35.168 43.9557 
                35.6022 44.4164 35.9071 45.0097C36.2121 45.5996 36.3645 46.289 36.3645 47.0779C36.3645 47.8601 36.2121 48.5478 35.9071 49.1411C35.6022 49.731 
                35.168 50.1917 34.6046 50.5232C34.0444 50.8513 33.3783 51.0154 32.606 51.0154ZM32.6159 49.3747C32.9673 49.3747 33.2606 49.2753 33.4959 
                49.0764C33.7312 48.8743 33.9086 48.5992 34.0279 48.2512C34.1505 47.9031 34.2118 47.5071 34.2118 47.0629C34.2118 46.6188 34.1505 46.2227 34.0279 
                45.8747C33.9086 45.5267 33.7312 45.2516 33.4959 45.0494C33.2606 44.8473 32.9673 44.7462 32.6159 44.7462C32.2613 44.7462 31.963 44.8473 31.7211 
                45.0494C31.4824 45.2516 31.3018 45.5267 31.1792 45.8747C31.0598 46.2227 31.0002 46.6188 31.0002 47.0629C31.0002 47.5071 31.0598 47.9031 31.1792 
                48.2512C31.3018 48.5992 31.4824 48.8743 31.7211 49.0764C31.963 49.2753 32.2613 49.3747 32.6159 49.3747ZM41.1919 51.0154C40.4097 51.0154 39.7369 
                50.8496 39.1735 50.5182C38.6133 50.1834 38.1825 49.7194 37.8809 49.1262C37.5826 48.5329 37.4334 47.8501 37.4334 47.0779C37.4334 46.2957 37.5842 
                45.6096 37.8858 45.0196C38.1908 44.4263 38.6233 43.964 39.1834 43.6325C39.7435 43.2978 40.4097 43.1304 41.182 43.1304C41.8482 43.1304 42.4315 
                43.2514 42.932 43.4933C43.4325 43.7353 43.8285 44.075 44.1202 44.5125C44.4119 44.95 44.5726 45.4637 44.6025 46.0537H42.6039C42.5475 45.6726 
                42.3984 45.366 42.1564 45.134C41.9178 44.8986 41.6046 44.781 41.2168 44.781C40.8887 44.781 40.602 44.8705 40.3567 45.0494C40.1148 45.2251 
                39.9258 45.482 39.79 45.82C39.6541 46.1581 39.5861 46.5674 39.5861 47.048C39.5861 47.5352 39.6524 47.9495 39.785 48.2909C39.9209 48.6323 40.1114
                48.8925 40.3567 49.0715C40.602 49.2504 40.8887 49.3399 41.2168 49.3399C41.4587 49.3399 41.6758 49.2902 41.8681 49.1908C42.0636 49.0914 42.2244 
                48.9472 42.3503 48.7583C42.4796 48.566 42.5641 48.3357 42.6039 48.0672H44.6025C44.5693 48.6505 44.4102 49.1643 44.1252 49.6084C43.8435 50.0492 
                43.454 50.3939 42.9569 50.6425C42.4597 50.8911 41.8714 51.0154 41.1919 51.0154ZM45.9585 50.8662V43.2298H48.0763V50.8662H45.9585ZM47.0224 
                42.2455C46.7075 42.2455 46.4374 42.1411 46.212 41.9323C45.9899 41.7201 45.8789 41.4666 45.8789 41.1716C45.8789 40.8799 45.9899 40.6297 46.212 
                40.4209C46.4374 40.2088 46.7075 40.1027 47.0224 40.1027C47.3372 40.1027 47.6057 40.2088 47.8278 40.4209C48.0531 40.6297 48.1658 40.8799 48.1658 
                41.1716C48.1658 41.4666 48.0531 41.7201 47.8278 41.9323C47.6057 42.1411 47.3372 42.2455 47.0224 42.2455ZM51.9505 51.0104C51.4632 51.0104 
                51.0291 50.9259 50.6479 50.7568C50.2667 50.5845 49.9651 50.3309 49.7431 49.9962C49.5243 49.6581 49.415 49.2372 49.415 48.7334C49.415 48.3092 
                49.4928 47.9529 49.6486 47.6645C49.8044 47.3762 50.0165 47.1441 50.285 46.9685C50.5534 46.7928 50.8584 46.6602 51.1998 46.5708C51.5444 46.4813 
                51.9057 46.4183 52.2836 46.3818C52.7277 46.3354 53.0856 46.2923 53.3574 46.2526C53.6292 46.2095 53.8264 46.1465 53.949 46.0637C54.0717 45.9808 
                54.133 45.8582 54.133 45.6958V45.6659C54.133 45.3511 54.0336 45.1075 53.8347 44.9351C53.6391 44.7628 53.3607 44.6766 52.9995 44.6766C52.6183 
                44.6766 52.315 44.7611 52.0897 44.9301C51.8643 45.0959 51.7151 45.3047 51.6422 45.5566L49.6834 45.3975C49.7828 44.9334 49.9784 44.5324 50.2701 
                44.1943C50.5617 43.853 50.9379 43.5911 51.3986 43.4088C51.8626 43.2232 52.3996 43.1304 53.0094 43.1304C53.4337 43.1304 53.8397 43.1801 54.2275 
                43.2796C54.6185 43.379 54.9649 43.5331 55.2665 43.7419C55.5714 43.9507 55.8117 44.2192 55.9874 44.5473C56.1631 44.8721 56.2509 45.2616 56.2509 
                45.7156V50.8662H54.2424V49.8073H54.1827C54.0601 50.0459 53.896 50.2564 53.6905 50.4387C53.485 50.6176 53.2381 50.7585 52.9498 50.8612C52.6614 
                50.9607 52.3283 51.0104 51.9505 51.0104ZM52.557 49.5487C52.8685 49.5487 53.1436 49.4874 53.3823 49.3648C53.6209 49.2388 53.8082 49.0698 53.9441 
                48.8577C54.08 48.6456 54.1479 48.4053 54.1479 48.1368V47.3264C54.0816 47.3695 53.9905 47.4093 53.8745 47.4458C53.7618 47.4789 53.6342 47.5104 
                53.4917 47.5402C53.3491 47.5667 53.2066 47.5916 53.0641 47.6148C52.9216 47.6347 52.7923 47.6529 52.6763 47.6695C52.4277 47.7059 52.2106 47.7639 
                52.025 47.8435C51.8394 47.923 51.6953 48.0307 51.5925 48.1666C51.4898 48.2992 51.4384 48.4649 51.4384 48.6638C51.4384 48.9521 51.5428 49.1726 
                51.7516 49.325C51.9637 49.4742 52.2322 49.5487 52.557 49.5487ZM60.0119 40.6844V50.8662H57.894V40.6844H60.0119Z" 
                fill={iconColor}/>
            <path 
                fillRule="evenodd" 
                clipRule="evenodd" 
                d="M40 11C34.48 11 30 15.48 30 21C30 26.52 34.48 31 40 31C45.52 31 50 26.52 50 21C50 15.48 45.52 11 40 11ZM39 28.93C35.05 28.44 32 25.08 32 21C32
                20.38 32.08 19.79 32.21 19.21L37 24V25C37 26.1 37.9 27 39 27V28.93ZM44 25C44.9 25 45.64 25.58 45.9 26.39C47.2 24.97 48 23.08 48 21C48 17.65 
                45.93 14.78 43 13.59V14C43 15.1 42.1 16 41 16H39V18C39 18.55 38.55 19 38 19H36V21H42C42.55 21 43 21.45 43 22V25H44Z" 
                fill={iconColor}/>
        </svg>
    )
}

export const Discover = ({ isActive, callback }) => {
    const iconColor = isActive ? "#FF0807" : "white"

    return(
        <svg
            onClick={callback}
            style={{ cursor: "pointer" }}
            width="80" 
            height="52" 
            viewBox="0 0 80 52" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg">
            <path 
                d="M14.759 50.8662H11.1496V40.6844H14.7888C15.8129 40.6844 16.6946 40.8882 17.4337 41.2959C18.1728 41.7003 18.7412 42.2819 19.1389 
                43.0409C19.54 43.7999 19.7405 44.7081 19.7405 45.7654C19.7405 46.826 19.54 47.7374 19.1389 48.4997C18.7412 49.262 18.1695 49.847 17.4237 
                50.2547C16.6813 50.6624 15.7931 50.8662 14.759 50.8662ZM13.3023 49.0218H14.6695C15.3058 49.0218 15.8411 48.9091 16.2753 48.6837C16.7128 48.455 
                17.0409 48.102 17.2597 47.6247C17.4817 47.1441 17.5928 46.5244 17.5928 45.7654C17.5928 45.013 17.4817 44.3982 17.2597 43.9209C17.0409 43.4436 
                16.7145 43.0923 16.2803 42.8669C15.8461 42.6415 15.3108 42.5289 14.6744 42.5289H13.3023V49.0218ZM21.2954 50.8662V43.2298H23.4133V50.8662H21.2954ZM22.3593
                42.2455C22.0444 42.2455 21.7743 42.1411 21.5489 41.9323C21.3269 41.7201 21.2158 41.4666 21.2158 41.1716C21.2158 40.8799 21.3269 40.6297 21.5489
                40.4209C21.7743 40.2088 22.0444 40.1027 22.3593 40.1027C22.6742 40.1027 22.9426 40.2088 23.1647 40.4209C23.3901 40.6297 23.5028 40.8799
                23.5028 41.1716C23.5028 41.4666 23.3901 41.7201 23.1647 41.9323C22.9426 42.1411 22.6742 42.2455 22.3593 42.2455ZM31.4635 45.4074L29.5246 
                45.5267C29.4914 45.361 29.4202 45.2119 29.3108 45.0793C29.2014 44.9434 29.0573 44.8357 28.8783 44.7561C28.7026 44.6733 28.4922 44.6318 
                28.2469 44.6318C27.9188 44.6318 27.642 44.7014 27.4166 44.8406C27.1913 44.9765 27.0786 45.1588 27.0786 45.3875C27.0786 45.5698 27.1515 
                45.7239 27.2973 45.8499C27.4432 45.9758 27.6934 46.0769 28.048 46.1531L29.4301 46.4316C30.1726 46.584 30.7261 46.8293 31.0906 47.1673C31.4552 
                47.5054 31.6375 47.9495 31.6375 48.4997C31.6375 49.0002 31.49 49.4394 31.195 49.8172C30.9034 50.195 30.5023 50.49 29.9919 50.7021C29.4848 
                50.911 28.8998 51.0154 28.2369 51.0154C27.2261 51.0154 26.4207 50.8049 25.8208 50.384C25.2242 49.9597 24.8745 49.383 24.7718 48.6539L26.8548 
                48.5445C26.9178 48.8527 27.0703 49.088 27.3122 49.2504C27.5542 49.4095 27.8641 49.4891 28.2419 49.4891C28.6131 49.4891 28.9114 49.4178 
                29.1368 49.2753C29.3655 49.1295 29.4815 48.9422 29.4848 48.7135C29.4815 48.5213 29.4003 48.3638 29.2412 48.2412C29.0821 48.1153 28.8369 
                48.0191 28.5054 47.9529L27.183 47.6894C26.4372 47.5402 25.8821 47.2817 25.5175 46.9138C25.1562 46.5459 24.9756 46.0769 24.9756 
                45.5068C24.9756 45.0163 25.1082 44.5937 25.3733 44.2391C25.6418 43.8844 26.018 43.611 26.5019 43.4188C26.9891 43.2265 27.5592 43.1304 28.2121 
                43.1304C29.1766 43.1304 29.9356 43.3343 30.4891 43.7419C31.0459 44.1496 31.3707 44.7048 31.4635 45.4074ZM36.4214 51.0154C35.6392 51.0154 
                34.9664 50.8496 34.403 50.5182C33.8428 50.1834 33.412 49.7194 33.1104 49.1262C32.8121 48.5329 32.6629 47.8501 32.6629 47.0779C32.6629 46.2957
                32.8137 45.6096 33.1153 45.0196C33.4202 44.4263 33.8528 43.964 34.4129 43.6325C34.973 43.2978 35.6392 43.1304 36.4115 43.1304C37.0777 
                43.1304 37.661 43.2514 38.1615 43.4933C38.662 43.7353 39.058 44.075 39.3497 44.5125C39.6414 44.95 39.8021 45.4637 39.8319 
                46.0537H37.8334C37.777 45.6726 37.6279 45.366 37.3859 45.134C37.1473 44.8986 36.8341 44.781 36.4463 44.781C36.1182 44.781 35.8315 44.8705 
                35.5862 45.0494C35.3443 45.2251 35.1553 45.482 35.0194 45.82C34.8836 46.1581 34.8156 46.5674 34.8156 47.048C34.8156 47.5352 34.8819 47.9495 
                35.0145 48.2909C35.1504 48.6323 35.3409 48.8925 35.5862 49.0715C35.8315 49.2504 36.1182 49.3399 36.4463 49.3399C36.6882 49.3399 36.9053 
                49.2902 37.0976 49.1908C37.2931 49.0914 37.4539 48.9472 37.5798 48.7583C37.7091 48.566 37.7936 48.3357 37.8334 48.0672H39.8319C39.7988 
                48.6505 39.6397 49.1643 39.3547 49.6084C39.0729 50.0492 38.6835 50.3939 38.1863 50.6425C37.6892 50.8911 37.1009 51.0154 36.4214 
                51.0154ZM44.6382 51.0154C43.866 51.0154 43.1981 50.8513 42.6347 50.5232C42.0745 50.1917 41.642 49.731 41.3371 49.1411C41.0322 
                48.5478 40.8797 47.8601 40.8797 47.0779C40.8797 46.289 41.0322 45.5996 41.3371 45.0097C41.642 44.4164 42.0745 43.9557 42.6347 
                43.6276C43.1981 43.2961 43.866 43.1304 44.6382 43.1304C45.4105 43.1304 46.0767 43.2961 46.6368 43.6276C47.2003 43.9557 47.6344 44.4164 
                47.9394 45.0097C48.2443 45.5996 48.3968 46.289 48.3968 47.0779C48.3968 47.8601 48.2443 48.5478 47.9394 49.1411C47.6344 49.731 47.2003 
                50.1917 46.6368 50.5232C46.0767 50.8513 45.4105 51.0154 44.6382 51.0154ZM44.6482 49.3747C44.9995 49.3747 45.2928 49.2753 45.5281 
                49.0764C45.7635 48.8743 45.9408 48.5992 46.0601 48.2512C46.1827 47.9031 46.2441 47.5071 46.2441 47.0629C46.2441 46.6188 46.1827 46.2227 
                46.0601 45.8747C45.9408 45.5267 45.7635 45.2516 45.5281 45.0494C45.2928 44.8473 44.9995 44.7462 44.6482 44.7462C44.2935 44.7462 43.9952 
                44.8473 43.7533 45.0494C43.5146 45.2516 43.334 45.5267 43.2114 45.8747C43.0921 46.2227 43.0324 46.6188 43.0324 47.0629C43.0324 47.5071 
                43.0921 47.9031 43.2114 48.2512C43.334 48.5992 43.5146 48.8743 43.7533 49.0764C43.9952 49.2753 44.2935 49.3747 44.6482 49.3747ZM56.6471 
                43.2298L53.9774 50.8662H51.591L48.9213 43.2298H51.1585L52.7444 48.6936H52.824L54.4049 43.2298H56.6471ZM60.9649 51.0154C60.1794 51.0154 
                59.5033 50.8563 58.9365 50.5381C58.3731 50.2166 57.9389 49.7625 57.634 49.1759C57.329 48.5859 57.1766 47.8882 57.1766 47.0828C57.1766 
                46.2973 57.329 45.6079 57.634 45.0146C57.9389 44.4214 58.3681 43.959 58.9216 43.6276C59.4784 43.2961 60.1314 43.1304 60.8804 43.1304C61.3842 
                43.1304 61.8532 43.2116 62.2874 43.374C62.7249 43.5331 63.106 43.7734 63.4308 44.0949C63.759 44.4164 64.0142 44.8208 64.1965 45.308C64.3788 
                45.7919 64.4699 46.3586 64.4699 47.0083V47.5899H58.0218V46.2774H62.4763C62.4763 45.9725 62.41 45.7024 62.2774 45.4671C62.1449 45.2317 
                61.9609 45.0478 61.7256 44.9152C61.4936 44.7793 61.2235 44.7114 60.9152 44.7114C60.5937 44.7114 60.3087 44.786 60.0601 44.9351C59.8148 
                45.0809 59.6226 45.2781 59.4834 45.5267C59.3442 45.772 59.2729 46.0454 59.2696 46.347V47.5949C59.2696 47.9727 59.3392 48.2992 59.4784 
                48.5743C59.6209 48.8494 59.8215 49.0615 60.08 49.2107C60.3385 49.3598 60.6451 49.4344 60.9997 49.4344C61.2351 49.4344 61.4505 49.4012 
                61.646 49.335C61.8416 49.2687 62.009 49.1692 62.1482 49.0367C62.2874 48.9041 62.3934 48.7417 62.4664 48.5494L64.4252 48.6787C64.3257 
                49.1494 64.1219 49.5603 63.8137 49.9117C63.5087 50.2597 63.1143 50.5315 62.6304 50.727C62.1498 50.9192 61.5947 51.0154 60.9649 
                51.0154ZM65.852 50.8662V43.2298H67.9053V44.5622H67.9848C68.124 44.0883 68.3577 43.7303 68.6858 43.4884C69.0139 43.2431 69.3918 43.1205 
                69.8193 43.1205C69.9254 43.1205 70.0397 43.1271 70.1624 43.1404C70.285 43.1536 70.3927 43.1718 70.4855 43.195V45.0743C70.3861 45.0445 
                70.2486 45.018 70.0729 44.9948C69.8972 44.9716 69.7365 44.96 69.5906 44.96C69.2791 44.96 69.0007 45.0279 68.7554 45.1638C68.5135 45.2964 
                68.3212 45.482 68.1787 45.7206C68.0395 45.9593 67.9699 46.2343 67.9699 46.5459V50.8662H65.852Z" 
                fill={iconColor}/>
            <path 
                d="M43.9772 23.4713H43.1872L42.9072 23.2013C44.1072 21.8013 44.7272 19.8913 44.3872 17.8613C43.9172 15.0813 41.5972 12.8613 38.7972 
                12.5213C34.5672 12.0013 31.0072 15.5613 31.5272 19.7913C31.8672 22.5913 34.0872 24.9113 36.8672 25.3813C38.8972 25.7213 40.8072 25.1013 42.2072
                23.9013L42.4772 24.1813V24.9713L46.7272 29.2213C47.1372 29.6313 47.8072 29.6313 48.2172 29.2213C48.6272 28.8113 48.6272 28.1413 48.2172 
                27.7313L43.9772 23.4713ZM37.9772 23.4713C35.4872 23.4713 33.4772 21.4613 33.4772 18.9713C33.4772 16.4813 35.4872 14.4713 37.9772 
                14.4713C40.4672 14.4713 42.4772 16.4813 42.4772 18.9713C42.4772 21.4613 40.4672 23.4713 37.9772 23.4713Z" 
                fill={iconColor}/>
        </svg>
    )
}

export const Library = ({ isActive, callback }) => {
    const iconColor = isActive ? "#FF0807" : "white"

    return(
        <svg 
            onClick={callback}
            style={{ cursor: "pointer" }}
            width="80" 
            height="54" 
            viewBox="0 0 80 54" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg">
            <path 
                d="M17.1584 50.8662V40.6844H19.3111V49.0914H23.6761V50.8662H17.1584ZM25.062 50.8662V43.2298H27.1799V50.8662H25.062ZM26.1259 42.2455C25.811 
                42.2455 25.5409 42.1411 25.3155 41.9323C25.0935 41.7201 24.9824 41.4666 24.9824 41.1716C24.9824 40.8799 25.0935 40.6297 25.3155 40.4209C25.5409 
                40.2088 25.811 40.1027 26.1259 40.1027C26.4408 40.1027 26.7092 40.2088 26.9313 40.4209C27.1567 40.6297 27.2694 40.8799 27.2694 41.1716C27.2694 
                41.4666 27.1567 41.7201 26.9313 41.9323C26.7092 42.1411 26.4408 42.2455 26.1259 42.2455ZM28.9162 50.8662V40.6844H31.0341V44.5125H31.0987C31.1915 
                44.307 31.3258 44.0982 31.5014 43.8861C31.6804 43.6707 31.9124 43.4917 32.1974 43.3492C32.4858 43.2033 32.8438 43.1304 33.2713 43.1304C33.8281 
                43.1304 34.3419 43.2762 34.8125 43.5679C35.2831 43.8563 35.6593 44.2921 35.9411 44.8754C36.2228 45.4555 36.3636 46.183 36.3636 47.058C36.3636 
                47.9098 36.2261 48.629 35.951 49.2156C35.6792 49.799 35.308 50.2414 34.8374 50.5431C34.37 50.8414 33.8464 50.9905 33.2663 50.9905C32.8554 
                50.9905 32.5057 50.9226 32.2173 50.7867C31.9323 50.6508 31.6986 50.4801 31.5163 50.2746C31.334 50.0658 31.1948 49.8553 31.0987 
                49.6432H31.0043V50.8662H28.9162ZM30.9893 47.048C30.9893 47.5021 31.0523 47.8982 31.1783 48.2362C31.3042 48.5743 31.4865 48.8378 31.7251 
                49.0267C31.9638 49.2123 32.2538 49.3051 32.5952 49.3051C32.9399 49.3051 33.2315 49.2107 33.4702 49.0218C33.7088 48.8295 33.8894 48.5644 34.0121 
                48.2263C34.138 47.8849 34.201 47.4922 34.201 47.048C34.201 46.6072 34.1397 46.2194 34.017 45.8847C33.8944 45.5499 33.7138 45.2881 33.4751 
                45.0992C33.2365 44.9102 32.9432 44.8158 32.5952 44.8158C32.2505 44.8158 31.9588 44.9069 31.7202 45.0892C31.4848 45.2715 31.3042 45.53 31.1783 
                45.8648C31.0523 46.1995 30.9893 46.594 30.9893 47.048ZM37.7768 50.8662V43.2298H39.8301V44.5622H39.9096C40.0488 44.0883 40.2825 43.7303 40.6106 
                43.4884C40.9387 43.2431 41.3166 43.1205 41.7441 43.1205C41.8502 43.1205 41.9645 43.1271 42.0872 43.1404C42.2098 43.1536 42.3175 43.1718 42.4103 
                43.195V45.0743C42.3109 45.0445 42.1734 45.018 41.9977 44.9948C41.822 44.9716 41.6613 44.96 41.5154 44.96C41.2039 44.96 40.9255 45.0279 40.6802 
                45.1638C40.4383 45.2964 40.246 45.482 40.1035 45.7206C39.9643 45.9593 39.8947 46.2343 39.8947 46.5459V50.8662H37.7768ZM45.4641 51.0104C44.9769 
                51.0104 44.5427 50.9259 44.1616 50.7568C43.7804 50.5845 43.4788 50.3309 43.2567 49.9962C43.038 49.6581 42.9286 49.2372 42.9286 48.7334C42.9286 
                48.3092 43.0065 47.9529 43.1623 47.6645C43.3181 47.3762 43.5302 47.1441 43.7987 46.9685C44.0671 46.7928 44.372 46.6602 44.7134 46.5708C45.0581 
                46.4813 45.4194 46.4183 45.7972 46.3818C46.2414 46.3354 46.5993 46.2923 46.8711 46.2526C47.1429 46.2095 47.3401 46.1465 47.4627 46.0637C47.5853 
                45.9808 47.6467 45.8582 47.6467 45.6958V45.6659C47.6467 45.3511 47.5472 45.1075 47.3484 44.9351C47.1528 44.7628 46.8744 44.6766 46.5131 
                44.6766C46.132 44.6766 45.8287 44.7611 45.6033 44.9301C45.378 45.0959 45.2288 45.3047 45.1559 45.5566L43.1971 45.3975C43.2965 44.9334 43.4921 
                44.5324 43.7837 44.1943C44.0754 43.853 44.4516 43.5911 44.9123 43.4088C45.3763 43.2232 45.9132 43.1304 46.5231 43.1304C46.9473 43.1304 47.3533 
                43.1801 47.7411 43.2796C48.1322 43.379 48.4786 43.5331 48.7802 43.7419C49.0851 43.9507 49.3254 44.2192 49.5011 44.5473C49.6767 44.8721 49.7646 
                45.2616 49.7646 45.7156V50.8662H47.756V49.8073H47.6964C47.5737 50.0459 47.4097 50.2564 47.2042 50.4387C46.9987 50.6176 46.7518 50.7585 46.4634 
                50.8612C46.1751 50.9607 45.842 51.0104 45.4641 51.0104ZM46.0707 49.5487C46.3822 49.5487 46.6573 49.4874 46.896 49.3648C47.1346 49.2388 47.3219 
                49.0698 47.4577 48.8577C47.5936 48.6456 47.6616 48.4053 47.6616 48.1368V47.3264C47.5953 47.3695 47.5041 47.4093 47.3881 47.4458C47.2755 47.4789 
                47.1478 47.5104 47.0053 47.5402C46.8628 47.5667 46.7203 47.5916 46.5778 47.6148C46.4353 47.6347 46.306 47.6529 46.19 47.6695C45.9414 47.7059 
                45.7243 47.7639 45.5387 47.8435C45.3531 47.923 45.2089 48.0307 45.1062 48.1666C45.0034 48.2992 44.9521 48.4649 44.9521 48.6638C44.9521 48.9521 
                45.0565 49.1726 45.2653 49.325C45.4774 49.4742 45.7459 49.5487 46.0707 49.5487ZM51.4077 50.8662V43.2298H53.4609V44.5622H53.5405C53.6797 44.0883 
                53.9134 43.7303 54.2415 43.4884C54.5696 43.2431 54.9474 43.1205 55.375 43.1205C55.4811 43.1205 55.5954 43.1271 55.718 43.1404C55.8407 43.1536 
                55.9484 43.1718 56.0412 43.195V45.0743C55.9418 45.0445 55.8042 45.018 55.6286 44.9948C55.4529 44.9716 55.2921 44.96 55.1463 44.96C54.8348 44.96 
                54.5563 45.0279 54.3111 45.1638C54.0691 45.2964 53.8769 45.482 53.7344 45.7206C53.5952 45.9593 53.5256 46.2343 53.5256 46.5459V50.8662H51.4077ZM58.6761 
                53.7298C58.4077 53.7298 58.1558 53.7083 57.9205 53.6652C57.6884 53.6254 57.4962 53.5741 57.3438 53.5111L57.821 51.9301C58.0696 52.0064 58.2933 52.0478 
                58.4922 52.0544C58.6944 52.0611 58.8684 52.0146 59.0142 51.9152C59.1634 51.8158 59.2843 51.6468 59.3771 51.4081L59.5014 51.085L56.7621 
                43.2298H58.9893L60.5703 48.8378H60.6499L62.2457 43.2298H64.4879L61.5199 51.6915C61.3774 52.1025 61.1835 52.4604 60.9382 52.7654C60.6963 53.0736 60.3897 
                53.3106 60.0185 53.4763C59.6473 53.6453 59.1998 53.7298 58.6761 53.7298Z" 
                fill={iconColor}/>
            <path 
                d="M34.4 18.2H34.6C35.37 18.2 36 18.83 36 19.6V26.6C36 27.37 35.37 28 34.6 28H34.4C33.63 28 33 27.37 33 26.6V19.6C33 18.83 33.63 18.2 34.4 18.2ZM40 
                14C40.77 14 41.4 14.63 41.4 15.4V26.6C41.4 27.37 40.77 28 40 28C39.23 28 38.6 27.37 38.6 26.6V15.4C38.6 14.63 39.23 14 40 14ZM45.6 22C46.37 22 47 22.63 
                47 23.4V26.6C47 27.37 46.37 28 45.6 28C44.83 28 44.2 27.37 44.2 26.6V23.4C44.2 22.63 44.83 22 45.6 22Z" 
                fill={iconColor}/>
        </svg>
    )
}

export const Playlist = ({ isActive, callback }) => {
    const iconColor = isActive ? "#FF0807" : "white"

    return(
        <svg 
            onClick={callback}
            style={{ cursor: "pointer" }}
            width="80" 
            height="54" 
            viewBox="0 0 80 54" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg">
            <path 
                d="M15.9543 50.8662V40.6844H19.9713C20.7436 40.6844 21.4015 40.8319 21.945 41.1269C22.4886 41.4185 22.9029 41.8245 23.1879 42.3449C23.4763 
                42.8619 23.6205 43.4585 23.6205 44.1347C23.6205 44.8108 23.4746 45.4074 23.183 45.9244C22.8913 46.4415 22.4687 46.8442 21.9152 47.1325C21.365 
                47.4209 20.6988 47.5651 19.9166 47.5651H17.3563V45.8399H19.5686C19.9829 45.8399 20.3243 45.7687 20.5928 45.6262C20.8646 45.4803 21.0667 45.2798 
                21.1993 45.0246C21.3352 44.7661 21.4031 44.4694 21.4031 44.1347C21.4031 43.7966 21.3352 43.5016 21.1993 43.2497C21.0667 42.9945 20.8646 42.7973 
                20.5928 42.6581C20.321 42.5156 19.9763 42.4443 19.5587 42.4443H18.107V50.8662H15.9543ZM27.0969 40.6844V50.8662H24.979V40.6844H27.0969ZM30.971 
                51.0104C30.4838 51.0104 30.0496 50.9259 29.6684 50.7568C29.2873 50.5845 28.9856 50.3309 28.7636 49.9962C28.5448 49.6581 28.4355 49.2372 28.4355 
                48.7334C28.4355 48.3092 28.5133 47.9529 28.6691 47.6645C28.8249 47.3762 29.037 47.1441 29.3055 46.9685C29.574 46.7928 29.8789 46.6602 30.2203 
                46.5708C30.565 46.4813 30.9262 46.4183 31.3041 46.3818C31.7482 46.3354 32.1061 46.2923 32.3779 46.2526C32.6497 46.2095 32.8469 46.1465 32.9695 
                46.0637C33.0922 45.9808 33.1535 45.8582 33.1535 45.6958V45.6659C33.1535 45.3511 33.0541 45.1075 32.8552 44.9351C32.6597 44.7628 32.3812 44.6766 
                32.02 44.6766C31.6388 44.6766 31.3356 44.7611 31.1102 44.9301C30.8848 45.0959 30.7356 45.3047 30.6627 45.5566L28.7039 45.3975C28.8034 44.9334 
                28.9989 44.5324 29.2906 44.1943C29.5822 43.853 29.9584 43.5911 30.4191 43.4088C30.8831 43.2232 31.4201 43.1304 32.0299 43.1304C32.4542 43.1304 
                32.8602 43.1801 33.248 43.2796C33.6391 43.379 33.9854 43.5331 34.287 43.7419C34.5919 43.9507 34.8322 44.2192 35.0079 44.5473C35.1836 44.8721 
                35.2714 45.2616 35.2714 45.7156V50.8662H33.2629V49.8073H33.2032C33.0806 50.0459 32.9165 50.2564 32.711 50.4387C32.5055 50.6176 32.2586 50.7585 
                31.9703 50.8612C31.6819 50.9607 31.3488 51.0104 30.971 51.0104ZM31.5775 49.5487C31.8891 49.5487 32.1642 49.4874 32.4028 49.3648C32.6414 49.2388 
                32.8287 49.0698 32.9646 48.8577C33.1005 48.6456 33.1684 48.4053 33.1684 48.1368V47.3264C33.1021 47.3695 33.011 47.4093 32.895 47.4458C32.7823 
                47.4789 32.6547 47.5104 32.5122 47.5402C32.3696 47.5667 32.2271 47.5916 32.0846 47.6148C31.9421 47.6347 31.8128 47.6529 31.6968 47.6695C31.4482 
                47.7059 31.2311 47.7639 31.0455 47.8435C30.8599 47.923 30.7158 48.0307 30.613 48.1666C30.5103 48.2992 30.4589 48.4649 30.4589 48.6638C30.4589 
                48.9521 30.5633 49.1726 30.7721 49.325C30.9842 49.4742 31.2527 49.5487 31.5775 49.5487ZM37.9486 53.7298C37.6801 53.7298 37.4282 53.7083 37.1929 
                53.6652C36.9609 53.6254 36.7687 53.5741 36.6162 53.5111L37.0935 51.9301C37.3421 52.0064 37.5658 52.0478 37.7646 52.0544C37.9668 52.0611 38.1408 
                52.0146 38.2867 51.9152C38.4358 51.8158 38.5568 51.6468 38.6496 51.4081L38.7739 51.085L36.0345 43.2298H38.2618L39.8428 48.8378H39.9223L41.5182 
                43.2298H43.7604L40.7923 51.6915C40.6498 52.1025 40.4559 52.4604 40.2107 52.7654C39.9687 53.0736 39.6621 53.3106 39.2909 53.4763C38.9197 53.6453 
                38.4723 53.7298 37.9486 53.7298ZM46.9621 40.6844V50.8662H44.8442V40.6844H46.9621ZM48.6586 50.8662V43.2298H50.7765V50.8662H48.6586ZM49.7226 
                42.2455C49.4077 42.2455 49.1376 42.1411 48.9122 41.9323C48.6901 41.7201 48.5791 41.4666 48.5791 41.1716C48.5791 40.8799 48.6901 40.6297 48.9122 
                40.4209C49.1376 40.2088 49.4077 40.1027 49.7226 40.1027C50.0374 40.1027 50.3059 40.2088 50.528 40.4209C50.7533 40.6297 50.866 40.8799 50.866 
                41.1716C50.866 41.4666 50.7533 41.7201 50.528 41.9323C50.3059 42.1411 50.0374 42.2455 49.7226 42.2455ZM58.8268 45.4074L56.8879 45.5267C56.8547 
                45.361 56.7835 45.2119 56.6741 45.0793C56.5647 44.9434 56.4205 44.8357 56.2416 44.7561C56.0659 44.6733 55.8554 44.6318 55.6102 44.6318C55.282 
                44.6318 55.0053 44.7014 54.7799 44.8406C54.5545 44.9765 54.4419 45.1588 54.4419 45.3875C54.4419 45.5698 54.5148 45.7239 54.6606 45.8499C54.8064 
                45.9758 55.0567 46.0769 55.4113 46.1531L56.7934 46.4316C57.5358 46.584 58.0893 46.8293 58.4539 47.1673C58.8185 47.5054 59.0008 47.9495 59.0008 
                48.4997C59.0008 49.0002 58.8533 49.4394 58.5583 49.8172C58.2667 50.195 57.8656 50.49 57.3552 50.7021C56.8481 50.911 56.2631 51.0154 55.6002 
                51.0154C54.5893 51.0154 53.7839 50.8049 53.184 50.384C52.5874 49.9597 52.2378 49.383 52.135 48.6539L54.2181 48.5445C54.2811 48.8527 54.4336 
                49.088 54.6755 49.2504C54.9175 49.4095 55.2274 49.4891 55.6052 49.4891C55.9764 49.4891 56.2747 49.4178 56.5001 49.2753C56.7288 49.1295 56.8448 
                48.9422 56.8481 48.7135C56.8448 48.5213 56.7636 48.3638 56.6045 48.2412C56.4454 48.1153 56.2001 48.0191 55.8687 47.9529L54.5463 47.6894C53.8005 
                47.5402 53.2454 47.2817 52.8808 46.9138C52.5195 46.5459 52.3389 46.0769 52.3389 45.5068C52.3389 45.0163 52.4714 44.5937 52.7366 44.2391C53.0051 
                43.8844 53.3812 43.611 53.8651 43.4188C54.3524 43.2265 54.9224 43.1304 55.5754 43.1304C56.5399 43.1304 57.2989 43.3343 57.8524 43.7419C58.4092 
                44.1496 58.734 44.7048 58.8268 45.4074ZM64.3962 43.2298V44.8208H59.7975V43.2298H64.3962ZM60.8415 41.4003H62.9594V48.5196C62.9594 48.7152 62.9893 
                48.8676 63.0489 48.977C63.1086 49.0831 63.1914 49.1576 63.2975 49.2007C63.4069 49.2438 63.5328 49.2654 63.6753 49.2654C63.7748 49.2654 63.8742 
                49.2571 63.9736 49.2405C64.0731 49.2206 64.1493 49.2057 64.2023 49.1958L64.5354 50.7718C64.4294 50.8049 64.2802 50.843 64.088 50.8861C63.8957 
                50.9325 63.6621 50.9607 63.387 50.9706C62.8766 50.9905 62.4291 50.9226 62.0447 50.7668C61.6635 50.611 61.3669 50.3691 61.1547 50.0409C60.9426 
                49.7128 60.8382 49.2985 60.8415 48.798V41.4003Z" 
                fill={iconColor}/>
            <path 
                fillRule="evenodd" 
                clipRule="evenodd" 
                d="M40 11C34.48 11 30 15.48 30 21C30 26.52 34.48 31 40 31C45.52 31 50 26.52 50 21C50 15.48 45.52 11 40 11ZM38.8 24.9L43.47 21.4C43.74 21.2 43.74 
                20.8 43.47 20.6L38.8 17.1C38.47 16.85 38 17.09 38 17.5V24.5C38 24.91 38.47 25.15 38.8 24.9ZM32 21C32 25.41 35.59 29 40 29C44.41 29 48 25.41 48 
                21C48 16.59 44.41 13 40 13C35.59 13 32 16.59 32 21Z" 
                fill={iconColor}/>
        </svg>
    )
}

export const Dashboard = ({ isActive, callback }) => {
    const iconColor = isActive ? "#FF0807" : "white"

    return(
        <svg
            onClick={callback}
            style={{ cursor: "pointer" }}
            width="80" 
            height="52" 
            viewBox="0 0 80 52" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg">
            <path 
                d="M7.60174 50.8662H3.99237V40.6844H7.63157C8.65572 40.6844 9.53735 40.8882 10.2765 41.2959C11.0156 41.7003 11.584 42.2819 11.9817 
                43.0409C12.3828 43.7999 12.5833 44.7081 12.5833 45.7654C12.5833 46.826 12.3828 47.7374 11.9817 48.4997C11.584 49.262 11.0123 49.847 10.2665 
                50.2547C9.52409 50.6624 8.63583 50.8662 7.60174 50.8662ZM6.14506 49.0218H7.51225C8.14862 49.0218 8.68389 48.9091 9.11808 48.6837C9.55558 
                48.455 9.8837 48.102 10.1025 47.6247C10.3245 47.1441 10.4355 46.5244 10.4355 45.7654C10.4355 45.013 10.3245 44.3982 10.1025 43.9209C9.8837 
                43.4436 9.55723 43.0923 9.12305 42.8669C8.68886 42.6415 8.15359 42.5289 7.51722 42.5289H6.14506V49.0218ZM16.3157 51.0104C15.8285 51.0104 
                15.3943 50.9259 15.0131 50.7568C14.632 50.5845 14.3304 50.3309 14.1083 49.9962C13.8896 49.6581 13.7802 49.2372 13.7802 48.7334C13.7802 
                48.3092 13.8581 47.9529 14.0138 47.6645C14.1696 47.3762 14.3817 47.1441 14.6502 46.9685C14.9187 46.7928 15.2236 46.6602 15.565 
                46.5708C15.9097 46.4813 16.271 46.4183 16.6488 46.3818C17.0929 46.3354 17.4509 46.2923 17.7227 46.2526C17.9944 46.2095 18.1916 46.1465 
                18.3143 46.0637C18.4369 45.9808 18.4982 45.8582 18.4982 45.6958V45.6659C18.4982 45.3511 18.3988 45.1075 18.1999 44.9351C18.0044 44.7628 
                17.726 44.6766 17.3647 44.6766C16.9835 44.6766 16.6803 44.7611 16.4549 44.9301C16.2295 45.0959 16.0804 45.3047 16.0075 45.5566L14.0487 
                45.3975C14.1481 44.9334 14.3436 44.5324 14.6353 44.1943C14.927 43.853 15.3031 43.5911 15.7638 43.4088C16.2279 43.2232 16.7648 43.1304 
                17.3746 43.1304C17.7989 43.1304 18.2049 43.1801 18.5927 43.2796C18.9838 43.379 19.3301 43.5331 19.6317 43.7419C19.9367 43.9507 20.177 
                44.2192 20.3526 44.5473C20.5283 44.8721 20.6161 45.2616 20.6161 45.7156V50.8662H18.6076V49.8073H18.5479C18.4253 50.0459 18.2612 50.2564 
                18.0558 50.4387C17.8503 50.6176 17.6033 50.7585 17.315 50.8612C17.0266 50.9607 16.6935 51.0104 16.3157 51.0104ZM16.9222 49.5487C17.2338 
                49.5487 17.5089 49.4874 17.7475 49.3648C17.9862 49.2388 18.1734 49.0698 18.3093 48.8577C18.4452 48.6456 18.5131 48.4053 18.5131 
                48.1368V47.3264C18.4469 47.3695 18.3557 47.4093 18.2397 47.4458C18.127 47.4789 17.9994 47.5104 17.8569 47.5402C17.7144 47.5667 17.5719 
                47.5916 17.4293 47.6148C17.2868 47.6347 17.1576 47.6529 17.0415 47.6695C16.793 47.7059 16.5759 47.7639 16.3903 47.8435C16.2047 47.923 
                16.0605 48.0307 15.9577 48.1666C15.855 48.2992 15.8036 48.4649 15.8036 48.6638C15.8036 48.9521 15.908 49.1726 16.1168 49.325C16.329 49.4742 
                16.5974 49.5487 16.9222 49.5487ZM28.6129 45.4074L26.674 45.5267C26.6409 45.361 26.5696 45.2119 26.4602 45.0793C26.3509 44.9434 26.2067 
                44.8357 26.0277 44.7561C25.852 44.6733 25.6416 44.6318 25.3963 44.6318C25.0682 44.6318 24.7914 44.7014 24.5661 44.8406C24.3407 44.9765 
                24.228 45.1588 24.228 45.3875C24.228 45.5698 24.3009 45.7239 24.4467 45.8499C24.5926 45.9758 24.8428 46.0769 25.1974 46.1531L26.5795 
                46.4316C27.322 46.584 27.8755 46.8293 28.2401 47.1673C28.6046 47.5054 28.7869 47.9495 28.7869 48.4997C28.7869 49.0002 28.6394 49.4394 
                28.3445 49.8172C28.0528 50.195 27.6518 50.49 27.1413 50.7021C26.6342 50.911 26.0492 51.0154 25.3864 51.0154C24.3755 51.0154 23.5701 50.8049 
                22.9702 50.384C22.3736 49.9597 22.0239 49.383 21.9212 48.6539L24.0043 48.5445C24.0672 48.8527 24.2197 49.088 24.4616 49.2504C24.7036 
                49.4095 25.0135 49.4891 25.3913 49.4891C25.7625 49.4891 26.0608 49.4178 26.2862 49.2753C26.5149 49.1295 26.6309 48.9422 26.6342 
                48.7135C26.6309 48.5213 26.5497 48.3638 26.3906 48.2412C26.2315 48.1153 25.9863 48.0191 25.6548 47.9529L24.3324 47.6894C23.5866 
                47.5402 23.0315 47.2817 22.6669 46.9138C22.3056 46.5459 22.125 46.0769 22.125 45.5068C22.125 45.0163 22.2576 44.5937 22.5227 44.2391C22.7912 
                43.8844 23.1674 43.611 23.6513 43.4188C24.1385 43.2265 24.7086 43.1304 25.3615 43.1304C26.326 43.1304 27.085 43.3343 27.6385 43.7419C28.1953 
                44.1496 28.5201 44.7048 28.6129 45.4074ZM32.2385 46.4514V50.8662H30.1206V40.6844H32.1788V44.5771H32.2683C32.4406 44.1264 32.719 43.7734 
                33.1035 43.5182C33.488 43.2597 33.9702 43.1304 34.5502 43.1304C35.0806 43.1304 35.5429 43.2464 35.9373 43.4784C36.3351 43.7071 36.6433 
                44.0369 36.862 44.4678C37.0841 44.8953 37.1935 45.4074 37.1902 46.004V50.8662H35.0723V46.3818C35.0756 45.9112 34.9563 45.545 34.7143 
                45.2831C34.4757 45.0213 34.1409 44.8904 33.71 44.8904C33.4217 44.8904 33.1665 44.9517 32.9444 45.0743C32.7257 45.1969 32.5533 45.3759 
                32.4274 45.6112C32.3047 45.8432 32.2418 46.1233 32.2385 46.4514ZM38.8967 50.8662V40.6844H41.0146V44.5125H41.0792C41.172 44.307 41.3062 
                44.0982 41.4819 43.8861C41.6609 43.6707 41.8929 43.4917 42.1779 43.3492C42.4663 43.2033 42.8242 43.1304 43.2518 43.1304C43.8086 43.1304 
                44.3223 43.2762 44.793 43.5679C45.2636 43.8563 45.6398 44.2921 45.9215 44.8754C46.2032 45.4555 46.3441 46.183 46.3441 47.058C46.3441 47.9098 
                46.2066 48.629 45.9315 49.2156C45.6597 49.799 45.2885 50.2414 44.8178 50.5431C44.3505 50.8414 43.8268 50.9905 43.2468 50.9905C42.8358 
                50.9905 42.4862 50.9226 42.1978 50.7867C41.9128 50.6508 41.6791 50.4801 41.4968 50.2746C41.3145 50.0658 41.1753 49.8553 41.0792 
                49.6432H40.9847V50.8662H38.8967ZM40.9698 47.048C40.9698 47.5021 41.0328 47.8982 41.1587 48.2362C41.2847 48.5743 41.467 48.8378 41.7056 
                49.0267C41.9442 49.2123 42.2343 49.3051 42.5756 49.3051C42.9203 49.3051 43.212 49.2107 43.4506 49.0218C43.6893 48.8295 43.8699 48.5644 
                43.9925 48.2263C44.1185 47.8849 44.1815 47.4922 44.1815 47.048C44.1815 46.6072 44.1201 46.2194 43.9975 45.8847C43.8749 45.5499 43.6942 
                45.2881 43.4556 45.0992C43.217 44.9102 42.9237 44.8158 42.5756 44.8158C42.2309 44.8158 41.9393 44.9069 41.7006 45.0892C41.4653 45.2715 
                41.2847 45.53 41.1587 45.8648C41.0328 46.1995 40.9698 46.594 40.9698 47.048ZM51.2076 51.0154C50.4353 51.0154 49.7675 50.8513 49.204 
                50.5232C48.6439 50.1917 48.2114 49.731 47.9064 49.1411C47.6015 48.5478 47.449 47.8601 47.449 47.0779C47.449 46.289 47.6015 45.5996 47.9064 
                45.0097C48.2114 44.4164 48.6439 43.9557 49.204 43.6276C49.7675 43.2961 50.4353 43.1304 51.2076 43.1304C51.9798 43.1304 52.646 43.2961 
                53.2061 43.6276C53.7696 43.9557 54.2038 44.4164 54.5087 45.0097C54.8136 45.5996 54.9661 46.289 54.9661 47.0779C54.9661 47.8601 54.8136 
                48.5478 54.5087 49.1411C54.2038 49.731 53.7696 50.1917 53.2061 50.5232C52.646 50.8513 51.9798 51.0154 51.2076 51.0154ZM51.2175 
                49.3747C51.5688 49.3747 51.8622 49.2753 52.0975 49.0764C52.3328 48.8743 52.5101 48.5992 52.6294 48.2512C52.7521 47.9031 52.8134 47.5071 
                52.8134 47.0629C52.8134 46.6188 52.7521 46.2227 52.6294 45.8747C52.5101 45.5267 52.3328 45.2516 52.0975 45.0494C51.8622 44.8473 51.5688 
                44.7462 51.2175 44.7462C50.8629 44.7462 50.5646 44.8473 50.3226 45.0494C50.084 45.2516 49.9034 45.5267 49.7807 45.8747C49.6614 46.2227 
                49.6017 46.6188 49.6017 47.0629C49.6017 47.5071 49.6614 47.9031 49.7807 48.2512C49.9034 48.5992 50.084 48.8743 50.3226 49.0764C50.5646 
                49.2753 50.8629 49.3747 51.2175 49.3747ZM58.5208 51.0104C58.0336 51.0104 57.5994 50.9259 57.2182 50.7568C56.8371 50.5845 56.5355 50.3309 
                56.3134 49.9962C56.0946 49.6581 55.9853 49.2372 55.9853 48.7334C55.9853 48.3092 56.0632 47.9529 56.2189 47.6645C56.3747 47.3762 56.5868 
                47.1441 56.8553 46.9685C57.1238 46.7928 57.4287 46.6602 57.7701 46.5708C58.1148 46.4813 58.476 46.4183 58.8539 46.3818C59.298 46.3354 
                59.656 46.2923 59.9277 46.2526C60.1995 46.2095 60.3967 46.1465 60.5194 46.0637C60.642 45.9808 60.7033 45.8582 60.7033 45.6958V45.6659C60.7033
                45.3511 60.6039 45.1075 60.405 44.9351C60.2095 44.7628 59.931 44.6766 59.5698 44.6766C59.1886 44.6766 58.8854 44.7611 58.66 44.9301C58.4346 
                45.0959 58.2855 45.3047 58.2125 45.5566L56.2537 45.3975C56.3532 44.9334 56.5487 44.5324 56.8404 44.1943C57.132 43.853 57.5082 43.5911 57.9689 
                43.4088C58.4329 43.2232 58.9699 43.1304 59.5797 43.1304C60.004 43.1304 60.41 43.1801 60.7978 43.2796C61.1889 43.379 61.5352 43.5331 61.8368 
                43.7419C62.1417 43.9507 62.382 44.2192 62.5577 44.5473C62.7334 44.8721 62.8212 45.2616 62.8212 45.7156V50.8662H60.8127V49.8073H60.753C60.6304 
                50.0459 60.4663 50.2564 60.2608 50.4387C60.0553 50.6176 59.8084 50.7585 59.5201 50.8612C59.2317 50.9607 58.8986 51.0104 58.5208 
                51.0104ZM59.1273 49.5487C59.4389 49.5487 59.714 49.4874 59.9526 49.3648C60.1912 49.2388 60.3785 49.0698 60.5144 48.8577C60.6503 48.6456 
                60.7182 48.4053 60.7182 48.1368V47.3264C60.6519 47.3695 60.5608 47.4093 60.4448 47.4458C60.3321 47.4789 60.2045 47.5104 60.062 47.5402C59.9194 
                47.5667 59.7769 47.5916 59.6344 47.6148C59.4919 47.6347 59.3626 47.6529 59.2466 47.6695C58.998 47.7059 58.781 47.7639 58.5953 47.8435C58.4097 
                47.923 58.2656 48.0307 58.1628 48.1666C58.0601 48.2992 58.0087 48.4649 58.0087 48.6638C58.0087 48.9521 58.1131 49.1726 58.3219 49.325C58.534 
                49.4742 58.8025 49.5487 59.1273 49.5487ZM64.4643 50.8662V43.2298H66.5176V44.5622H66.5971C66.7363 44.0883 66.97 43.7303 67.2981 43.4884C67.6262 
                43.2431 68.0041 43.1205 68.4316 43.1205C68.5377 43.1205 68.652 43.1271 68.7747 43.1404C68.8973 43.1536 69.005 43.1718 69.0978 
                43.195V45.0743C68.9984 45.0445 68.8609 45.018 68.6852 44.9948C68.5095 44.9716 68.3488 44.96 68.2029 44.96C67.8914 44.96 67.613 45.0279 67.3677 
                45.1638C67.1258 45.2964 66.9335 45.482 66.791 45.7206C66.6518 45.9593 66.5822 46.2343 66.5822 46.5459V50.8662H64.4643ZM72.6513 50.9905C72.0713 
                50.9905 71.5459 50.8414 71.0753 50.5431C70.608 50.2414 70.2367 49.799 69.9616 49.2156C69.6899 48.629 69.554 47.9098 69.554 47.058C69.554 
                46.183 69.6948 45.4555 69.9766 44.8754C70.2583 44.2921 70.6328 43.8563 71.1001 43.5679C71.5708 43.2762 72.0862 43.1304 72.6463 43.1304C73.0739 
                43.1304 73.4302 43.2033 73.7152 43.3492C74.0036 43.4917 74.2356 43.6707 74.4112 43.8861C74.5902 44.0982 74.7261 44.307 74.8189 
                44.5125H74.8835V40.6844H76.9964V50.8662H74.9084V49.6432H74.8189C74.7195 49.8553 74.5786 50.0658 74.3963 50.2746C74.2173 50.4801 73.9837 
                50.6508 73.6953 50.7867C73.4103 50.9226 73.0623 50.9905 72.6513 50.9905ZM73.3224 49.3051C73.6638 49.3051 73.9522 49.2123 74.1875 
                49.0267C74.4261 48.8378 74.6084 48.5743 74.7344 48.2362C74.8636 47.8982 74.9283 47.5021 74.9283 47.048C74.9283 46.594 74.8653 46.1995 74.7393 
                45.8648C74.6134 45.53 74.4311 45.2715 74.1925 45.0892C73.9538 44.9069 73.6638 44.8158 73.3224 44.8158C72.9744 44.8158 72.6811 44.9102 72.4425 
                45.0992C72.2038 45.2881 72.0232 45.5499 71.9006 45.8847C71.7779 46.2194 71.7166 46.6072 71.7166 47.048C71.7166 47.4922 71.7779 47.8849 71.9006 
                48.2263C72.0265 48.5644 72.2071 48.8295 72.4425 49.0218C72.6811 49.2107 72.9744 49.3051 73.3224 49.3051Z" 
                fill={iconColor}/>
            <path 
                d="M34 28H37V22H43V28H46V19L40 14.5L34 19V28ZM34 30C33.45 30 32.9793 29.8043 32.588 29.413C32.196 29.021 32 28.55 32 28V19C32 18.6833 32.071 
                18.3833 32.213 18.1C32.3543 17.8167 32.55 17.5833 32.8 17.4L38.8 12.9C38.9833 12.7667 39.175 12.6667 39.375 12.6C39.575 12.5333 39.7833 12.5 
                40 12.5C40.2167 12.5 40.425 12.5333 40.625 12.6C40.825 12.6667 41.0167 12.7667 41.2 12.9L47.2 17.4C47.45 17.5833 47.646 17.8167 47.788 
                18.1C47.9293 18.3833 48 18.6833 48 19V28C48 28.55 47.8043 29.021 47.413 29.413C47.021 29.8043 46.55 30 46 30H41V24H39V30H34Z" 
                fill={iconColor}/>
        </svg>
    )
}