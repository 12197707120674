// Customizable Area Start
import React from "react";
import {
    Box,
    styled,
    Button,
} from "@mui/material";
import HostLoginController, {
    Props,
} from "./HostLoginController";
import { hostBg } from "./assets";

export default class HostLogin extends HostLoginController {
    constructor(props: Props) {
        super(props);
    }
    
    render() {
        return (
            <StyledWrapperSignUp>
             <Box className="container">
                <img src={hostBg} alt="hostBg" className="image"/>
                <Box className="subContainer">
                    <Box className="title">Become A Host</Box>
                    <Box className="desc">Start the journey to build, engage and monetize your audience</Box>
                </Box>
                <a href="/HostPaymentPlan" style={{textDecoration : "none"}}><Button className="btn">Begin to Setup My Channel</Button></a>
             </Box>
            </StyledWrapperSignUp>
        );
    }

}
const StyledWrapperSignUp = styled("div")({
    margin: "0 auto",
    height: "auto",
    width: "100%",
    background: "black",
    "& *": {
        boxSizing: "border-box",
    },
    "& .btn" :{
        margin : "auto",
        background : "#FF0807",
        color : "white",
        borderRadius : "8px",
        height : "9%",
        width : "20%",
        display : "flex",
        justifyContent : "center",
        alignItems : "center",
        fontWeight : 900,
        fontSize : "14px",
        fontFamily : "poppins",
        textTransform : "capitalize",
        "&:hover" : {
            background : "#FF0807",        
        }
    },
    "& .desc":{
        margin : "auto",
        textAlign : "center",
        fontFamily : "poppins",
        fontSize : "16px",
        fontWeight : 400
    },
    "& .title":{
        color : "white",
        fontFamily : "poppins",
        textAlign : "center",
        fontSize : "30px",
        fontWeight : 900
    },
    "& .subContainer":{
        margin : "auto",
        display:"flex",
        flexDirection : "column",
        width:"80%",
        height : "23%",
        marginTop : "2%"
    },
    "& .container":{
    color : "white",
    height:"100%",
    width:"90%",
    margin:"auto",
    background:"#44403C",
    marginBottom : "3%"
    },
    "& .image":{
        display:"flex",
        height : "40%",
        width : "80%",
        paddingTop : "5%",
        boxSizing: "content-box",
        margin:"auto",       
    }
})
// Customizable Area End
