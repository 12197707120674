export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgVisbility = require("../assets/visibility.svg");
export const imgVisbilityOff = require("../assets/visibilityOff.svg");
export const backgroundImg = require("../assets/backgroundImg.jpeg").default;
export const mainLogo = require("../assets/mainLogo.png").default;
export const logoSvg = require("../assets/logoSvg.svg").default;
export const visibility_icon = require("../assets/visibility_copy.svg").default;
export const checkbox = require("../assets/checkbox.svg").default;
export const visibility_cross = require("../assets/visibilityCross.svg").default;
export const checkboxTicked  = require("../assets/tickedCheckbox.png").default;
export const hostBg  = require("../assets/hostImage.png").default;