import React from "react";

// Customizable Area Start
import {
  Box,
  styled,
  Grid,
  TextField,
  InputAdornment, IconButton,Button
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { backgroundImg,logoSvg, visibility_icon,checkbox, visibility_cross, checkboxTicked } from "./assets";
import { ResolveCondition } from "../../utilities/src/CustomHelpers";
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  validationSchema = Yup.object({
    email: Yup.string().email("Invalid email address").required("Required"),
    password: Yup.string().required("Required"),
  });
  // Customizable Area End
  render() {
  // Customizable Area Start
  // Customizable Area End
    return (
      // Customizable Area Start
      <StyledWrapperLogin>
      <Grid className="container">        
        <Grid className="leftContainer">  
          <img src={logoSvg} alt="mainLogo" className="leftImage"/>
          <Grid className="leftContent">Where every voice is heard!</Grid>
          <Grid className="leftSecondary">Build, engage, and monetize your audience.<br/> Learn more <span style={{color: "#2563EB", textDecoration: "underline"}}>here</span>!</Grid>   
        </Grid>
        <Grid className="rightContainer">
          <Grid className="rightTitle">Login</Grid>
          <Grid className="rightContent">
            <Formik
              initialValues={{ email: '', password: '' }}
              validationSchema={this.validationSchema}   
              onSubmit={(values, { setSubmitting }) => {
               this.loginApi(values)
            }}           
            >
              {({ values, handleChange, handleBlur, setFieldValue ,handleSubmit,errors}) => (
                <Form onSubmit={handleSubmit}>
                  <Grid className="fields"> 
                    <Box>
                      <label className="label">Username<span>*</span></label>
                    </Box>
                    <Field
                      as={TextField}
                      id="outlined-basic"
                      name="email"
                      data-test-id="txtEmail"
                      sx={{
                        width: "100%",
                        marginTop: "4%",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#CBD5E1", 
                          },
                          "&:hover fieldset": {
                            borderColor: "#CBD5E1", 
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#CBD5E1", 
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: "#CBD5E1", 
                        },
                        "& .MuiInputBase-input": {
                          color: "#CBD5E1",
                        },
                      }}
                      placeholder="Enter your email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />                   
                  </Grid>
                  <div className="errors">{errors.email}</div>
                  
                  <Grid className="fields">
                    <Box>
                      <label className="label">Password<span>*</span></label>
                    </Box>
                    <Field
                      as={TextField}
                      id="outlined-basic"
                      name="password"
                      data-test-id="txtInputPassword"
                      sx={{
                        width: "100%",
                        marginTop: "4%",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#CBD5E1", 
                          },
                          "&:hover fieldset": {
                            borderColor: "#CBD5E1", 
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#CBD5E1", 
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: "#CBD5E1", 
                        },
                        "& .MuiInputBase-input": {
                          color: "#CBD5E1",
                        },
                      }}
                      type={ResolveCondition(this.state.enablePasswordField,'password','text')}
                      placeholder="Enter your password"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"                      
                              edge="end"
                              data-test-id="clickshowPassword"
                              onClick={() => setFieldValue('password', !this.state.enablePasswordField)}
                            >
                              <img
                                src={this.state.enablePasswordField ? visibility_icon : visibility_cross}                    
                                alt="toggle visibility"
                                style={{ width: "24px", height: "24px", cursor: "pointer" }}
                              />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <div className="errors">{errors.password}</div>

                  <Grid className="rightBottom">
                    <Box className="rightBottomBox">
                      <img src={this.state.checkedRememberMe ? checkbox : checkboxTicked} alt="checkbox" data-test-id = "tickmark" onClick={this.handleTicked}/>
                      <Box>
                        <label className="labelRemember">Remember me</label>
                      </Box>
                    </Box>
                    <Box>
                      <a className="labelForgotPass" href="/EmailForgotPassword">Forgot Password?</a>
                    </Box>
                  </Grid>
                  <Button className="loginButton" data-test-id="login-btn" type="submit">Login</Button>
                  <Box className="signUp">You do not have an account?  <a href="EmailAccountRegistration" style={{ textDecoration: "none", color: "white", cursor: "pointer" }}>Sign up</a></Box>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Grid>
    </StyledWrapperLogin>
      // Customizable Area End
    );
  }

}

// Customizable Area Start
const StyledWrapperLogin = styled("div")({
  margin: "0 auto",
  height: "100%",
  width: "100%",
  "& *": {
    boxSizing: "border-box",
  },
  "& .errors":{
    color : "red",
  },
  "& .container":{
    backgroundImage:`url(${backgroundImg})`, 
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    height: "100%",
    display:"flex",
    flexDirection:"row"
  },
  "& .leftContainer":{
    width : "50%",
    display:"flex",
    flexDirection:"column",
    boxSizing:"border-box",
    alignItems:"center",
    marginLeft:"10%"
  },
  "& .leftImage":{
    marginTop:"27%",
    maxWidth:"400px",
    width:"100%"
  },
  "& .leftContent":{
    color : "white",
    fontSize:"26px",
    maxWidth:"380px",
    width:"100%",
    height : "100%",
    maxHeight:"48px",
    fontFamily:"Poppins",
    marginTop:"16px",
    fontWeight:900
  },
  "& .leftSecondary":{
    color : "white",
    height:"100%",
    maxHeight:"52px",
    width : "100%",
    maxWidth:"380px",
    fontFamily:"Poppins",
    fontSize:"16px",
    textAlign:"center"
  },
  "& .rightContainer":{
    width : "50%",
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    marginRight:"10%"
  },
  "& .rightTitle":{
    color:"white",
    marginTop:"20%",
    height:"100%",
    width:"100%",
    maxWidth:"392px",
    maxHeight :"28px",
    textAlign:"center",
    fontWeight:900,
    fontSize:"20px",
    fontFamily:"Poppins"
  },
  "& .rightContent":{
    height:"100%",
    width:"100%",
    maxWidth:"392px",
    maxHeight:"214px",
    marginTop:"30px"
  },
  "& .fields":{
    marginTop : "5%"
  },
  "& .label":{
    color:"white",
    fontFamily:"Poppins",
    fontSize:"14px",
    fontWeight:900
  },
  "& .rightBottom":{
    marginTop: "7%",
    display: 'flex',
    alignItems: 'center' ,
    justifyContent:"space-between"
  },
  "& .rightBottomBox":{
    display:"flex",
    alignItems:"center"
  },
  "& .labelRemember":{
    color: "white",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 900,
    marginLeft: '8px'
  },
  "& .labelForgotPass":{
    color: "white",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 900,
    textDecoration : "none"
  },
  "& .signUp":{
    color:"white",
    fontFamily : "Poppins",
    textAlign:"center",
    fontWeight:400,
    fontSize:"12px",
    paddingTop:"15px"
  },
  "& .loginButton":{
    backgroundColor: '#FF0807',
    marginTop:"25px",
    color: 'white', 
    height : "100%",
    width:"100%",
    maxWidth : "392px",
    maxHeight : "52px",
    fontSize:"18px",
    fontWeight : 900,
    fontFamily : "Poppins",
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    borderRadius : "8px",
    marginBottom:"10px",    
    "&:hover": {
      background: "#FF0807",
      color: "white"
  },
  },
  "@media screen and (max-width: 1000px)": {
    "& .container": {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      margin: "0",
      backgroundSize: "cover", 
      backgroundPosition: "center",
      height: "100vh",    
    },
    "& .leftContainer, & .rightContainer": {
      width: "80%",
      margin: "0 auto",
      marginTop: "0",
    },
    "& .rightTitle": {
      marginTop: "5%",
    },
    "& .leftImage":{
      marginTop:"0",    
    },
    "& .rightContent":{
      marginTop :"0"
    }
  }
  
})
// Customizable Area End