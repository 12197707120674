// Customizable Area Start
import React from "react";
import {
    Box,
    styled,
    Button,
    Grid,
    TextField,    
} from "@mui/material";
import EmailRegistrationSocialLinksController, {
    Props,
} from "./EmailRegistrationSocialLinksController";
import { backgroundImg, image_socialLinks, logoSvg } from "./assets";
import { handleNavigation } from "../../utilities/src/CustomHelpers";
import { Formik,Form } from 'formik'
import { setStorageData } from "../../../framework/src/Utilities";


export default class EmailRegistrationSocialLinks extends EmailRegistrationSocialLinksController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <StyleWrapperSocialLink>
                <Grid className="container">
                    <Grid className="leftContainer">
                        <Formik
                            initialValues={{
                                facebook: "",
                                instagram: "",
                                x: "",
                                linkedin: ""
                            }}
                            data-test-id="formikWrapper"
                            onSubmit={(values) => {
                                setStorageData("SocialLinks",JSON.stringify(values))
                                handleNavigation("EmailRegistrationCategories",this.props)
                            }}
                        >
                            {({ handleChange, handleSubmit, values }) => (
                                <Form onSubmit={handleSubmit}>
                                    <Box className="title">Social Media Links</Box>
                                    <Box className="logoImg">
                                        <img src={logoSvg} alt="logo" />
                                    </Box>

                                    <Grid className="fields">
                                        <Box>
                                            <label className="label">Facebook</label>
                                        </Box>
                                        <TextField
                                            id="outlined-basic"
                                            name="facebook"
                                            value={values.facebook}
                                            onChange={handleChange}
                                            sx={{
                                                marginTop: "4%",
                                                width: "100%",
                                                "& .MuiOutlinedInput-root": {
                                                    "& fieldset": {
                                                        borderColor: "#CBD5E1",
                                                    },
                                                    "&:hover fieldset": {
                                                        borderColor: "#CBD5E1",
                                                    },
                                                    "&.Mui-focused fieldset": {
                                                        borderColor: "#CBD5E1",
                                                    },
                                                },
                                                "& .MuiInputLabel-root": {
                                                    color: "#CBD5E1",
                                                },
                                                "& .MuiInputBase-input": {
                                                    color: "#CBD5E1",
                                                },
                                            }}
                                        />
                                    </Grid>

                                    <Grid className="fields">
                                        <Box>
                                            <label className="label">Instagram</label>
                                        </Box>
                                        <TextField
                                            id="outlined-basic"
                                            name="instagram"
                                            value={values.instagram}
                                            onChange={handleChange}
                                            sx={{
                                                marginTop: "4%",
                                                width: "100%",
                                                "& .MuiOutlinedInput-root": {
                                                    "& fieldset": {
                                                        borderColor: "#CBD5E1",
                                                    },
                                                    "&:hover fieldset": {
                                                        borderColor: "#CBD5E1",
                                                    },
                                                    "&.Mui-focused fieldset": {
                                                        borderColor: "#CBD5E1",
                                                    },
                                                },
                                                "& .MuiInputLabel-root": {
                                                    color: "#CBD5E1",
                                                },
                                                "& .MuiInputBase-input": {
                                                    color: "#CBD5E1",
                                                },
                                            }}
                                        />
                                    </Grid>

                                    <Grid className="fields">
                                        <Box>
                                            <label className="label">X</label>
                                        </Box>
                                        <TextField
                                            id="outlined-basic"
                                            name="x"
                                            value={values.x}
                                            onChange={handleChange}
                                            sx={{
                                                width: "100%",
                                                marginTop: "4%",
                                                "& .MuiOutlinedInput-root": {
                                                    "& fieldset": {
                                                        borderColor: "#CBD5E1",
                                                    },
                                                    "&:hover fieldset": {
                                                        borderColor: "#CBD5E1",
                                                    },
                                                    "&.Mui-focused fieldset": {
                                                        borderColor: "#CBD5E1",
                                                    },
                                                },
                                                "& .MuiInputLabel-root": {
                                                    color: "#CBD5E1",
                                                },
                                                "& .MuiInputBase-input": {
                                                    color: "#CBD5E1",
                                                },
                                            }}
                                        />
                                    </Grid>

                                    <Grid className="fields">
                                        <Box>
                                            <label className="label">LinkedIn</label>
                                        </Box>
                                        <TextField
                                            id="outlined-basic"
                                            name="linkedin"
                                            value={values.linkedin}
                                            onChange={handleChange}
                                            sx={{
                                                width: "100%",
                                                marginTop: "4%",
                                                "& .MuiOutlinedInput-root": {
                                                    "& fieldset": {
                                                        borderColor: "#CBD5E1",
                                                    },
                                                    "&:hover fieldset": {
                                                        borderColor: "#CBD5E1",
                                                    },
                                                    "&.Mui-focused fieldset": {
                                                        borderColor: "#CBD5E1",
                                                    },
                                                },
                                                "& .MuiInputLabel-root": {
                                                    color: "#CBD5E1",
                                                },
                                                "& .MuiInputBase-input": {
                                                    color: "#CBD5E1",
                                                },
                                            }}
                                        />
                                    </Grid>

                                    <Button type="button" className="btnSkip">
                                        <b style={{ textTransform: "initial" }}>Skip this step</b>
                                    </Button>
                                    <Button type="submit" className="btnContinue" data-test-id="submitBtn">
                                        <b>Continue</b>
                                    </Button>
                                </Form>
                            )}
                        </Formik>
                    </Grid>
                    <Grid className="rightContainer">
                        <img src={image_socialLinks} alt="image_socialLinks" className="imageSignUp" />
                    </Grid>
                </Grid>
            </StyleWrapperSocialLink>

        )
    }
}

const StyleWrapperSocialLink = styled('div')({
    margin: "0 auto",
    height: "auto",
    width: "100%",
    background: "black",
    "& *": {
        boxSizing: "border-box",
    },
    "& .imageSignUp": {
        width: "100%",
        height: "auto",
    },
    "& .leftContainer": {
        padding: "3% 10%",
        width: "45%",
    },
    "& .btnSkip": {
        width: "100%",
        height: "6%",
        color: "white",
        background: "#312F2D",
        fontFamily: "Poppins",
        borderRadius: "8px",
        fontWeight: 900,
        fontSize: "18px",
        marginTop: "10%",
        textTransform: "capitalize",
        "&:hover": {
            background: "#312F2D",
            color: "white"
        },
    },
    "& .btnContinue": {
        width: "100%",
        height: "6%",
        color: "white",
        background: "#FF0807",
        fontFamily: "Poppins",
        borderRadius: "8px",
        fontWeight: 900,
        fontSize: "18px",
        marginTop: "5%",
        textTransform: "capitalize",
        "&:hover": {
            background: "#FF0807",
            color: "white"
        },
    },
    "& .logoImg": {
        justifyContent: "center",
        display: "flex",
    },
    "& .label": {
        fontSize: "14px",
        color: "white",
        fontFamily: "Poppins",
        fontWeight: 900
    },
    "& .container": {
        backgroundImage: `url(${backgroundImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "row"
    },
    "& .title": {
        fontWeight: 900,
        fontSize: "20px",
        color: "white",
        fontFamily: "Poppins",
        textAlign: "center",
        marginBottom: "4%",
        marginTop: "7%",
    },
    "& .fields": {
        marginTop: "4%"
    },
    "& .rightContainer": {
        display: "flex",
        width: "55%",
        justifyContent: "flex-start",
        paddingTop: "5%",
        alignItems: "flex-start",
    },
})
// Customizable Area End